import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { UserProps } from 'utils/props'
import { useMarket } from 'contexts/MarketContext'
import { api } from 'services/api'
import { useToast } from 'contexts/ToastContext'
import { login } from 'services/auth'

import * as S from './styles'

interface FormData {
  email: string
  password: string
  isEmployee: boolean
}

interface ResponseProps {
  token: string
  market: object
  user: UserProps
}

interface Props extends RouteComponentProps {}

const Login: React.FC<Props> = ({ history }) => {
  const { setMarket } = useMarket()
  const [loading, setLoading] = useState(false)

  const { showToast } = useToast()

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Você precisa inserir um e-mail válido')
      .required('E-mail obrigatório'),
    password: yup.string().required('Senha obrigatória')
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = useCallback(
    async (data: FormData) => {
      setLoading(true)

      try {
        const { data: response } = await api.post<ResponseProps>('auth', data)

        if (response.market) {
          login(response)
          setMarket(response.market)

          history.push('/dashboard/')
        } else {
          showToast({
            message: 'Por Favor entrar em contato com suporte.',
            type: 'error'
          })
          return
        }
      } catch (err) {
        const message =
          err?.response?.data?.error ||
          'Por Favor entrar em contato com suporte.'

        showToast({ message, type: 'error' })
      } finally {
        setLoading(false)
      }
    },
    [showToast, history]
  )

  return (
    <S.Body>
      <S.Left>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Col>
            <S.Label htmlFor="email">E-mail</S.Label>
            <S.Input
              id="email"
              name="email"
              className={errors.email && 'is-invalid'}
              type="email"
              placeholder="E-mail"
              {...register('email')}
            />

            {errors.email && <S.Error>{errors.email.message}</S.Error>}
          </S.Col>

          <S.Col>
            <S.Label htmlFor="password">Senha</S.Label>
            <S.Input
              id="password"
              name="password"
              className={errors.password && 'is-invalid'}
              type="password"
              placeholder="Senha"
              {...register('password')}
            />

            {errors.password && <S.Error>{errors.password.message}</S.Error>}
          </S.Col>

          {/* <S.Col>
              <S.Label>Logar como funcionário</S.Label>
              <div>
                <S.CheckBox
                  name="isEmployee"
                  className={errors.isEmployee && 'is-invalid'}
                  type="checkbox"
                  defaultChecked
                  {...register('isEmployee')}
                />
              </div>
            </S.Col> */}

          <S.Button type="submit" disabled={loading}>
            {loading ? (
              <div className="divLoading">
                <span className="spanLoading" />
                <span className="spanLoading" />
                <span className="spanLoading" />
                <span className="spanLoading" />
              </div>
            ) : (
              'Entrar'
            )}
          </S.Button>
        </S.Form>
      </S.Left>
    </S.Body>
  )
}

export default Login
