import React, { Fragment, InputHTMLAttributes } from 'react'

import LabelComponent from 'components/LabelComponent'

import * as S from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  money?: boolean
}

const InputComponent: React.FC<Props> = ({ money, label, ...rest }) => {
  if (money) {
    return (
      <Fragment>
        {label && <LabelComponent htmlFor={rest.id}>{label}</LabelComponent>}
        <S.InputMoney
          money
          currency="BRL"
          config={{
            locale: 'pt-BR',
            formats: {
              number: {
                BRL: {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }
              }
            }
          }}
          {...rest}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      {label && <LabelComponent htmlFor={rest.id}>{label}</LabelComponent>}
      <S.Input {...rest} />
    </Fragment>
  )
}

export default InputComponent
