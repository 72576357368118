import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin: 0;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    padding: 14px 46px;
    color: #17a2b8;
    border: none;
    border-bottom: 1px solid gray;
    cursor: pointer;

    &:hover {
      color: #ffffff;
      background-color: #17a2b8;
    }
  }
`
