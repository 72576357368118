import React from 'react'

import PagesTable from './PagesTable'

import * as S from './styles'

const Pages: React.FC = () => {
  return (
    <S.Container>
      <PagesTable />
    </S.Container>
  )
}

export default Pages
