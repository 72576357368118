import React, { useCallback, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { OrderProps } from 'utils/props'
import { useOrder } from 'contexts/OrderContext'
import { formatPrice } from 'utils/utils'

import DatePicker from 'components/DatePicker'
import OrderModal from './OrderModal'

import * as S from './styles'

const OrdersTable: React.FC = () => {
  const { isLoading, period, onDate, selectedDate, setSelectedDate, orders } =
    useOrder()
  const [order, setOrder] = useState<OrderProps>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleEditOrder = useCallback(
    (orderValue: OrderProps) => {
      setOrder(orderValue)
      setIsOpen(!isOpen)
    },
    [isOpen]
  )

  const listStatus = [
    {
      title: 'Aguardando',
      status: 'PLACED'
    },
    {
      title: 'Produção',
      status: 'CONFIRMED'
    },
    {
      title: 'Pronto',
      status: 'READ'
    },
    {
      title: 'Saiu para entrega',
      status: 'DISPATCHED'
    },
    {
      title: 'Entregue',
      status: 'CONCLUDED'
    },
    {
      title: 'Cancelado',
      status: 'CANCELLED'
    }
  ]

  return (
    <S.Container>
      <S.Header>
        <h1>Listado de Pedidos</h1>

        <div>
          <DatePicker
            startDate={period.startDate}
            endDate={period.endDate}
            onDate={onDate}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />

          <S.CountOrders>
            <span>{orders.length}</span>
          </S.CountOrders>
        </div>
      </S.Header>

      {isLoading ? (
        <S.Empty>Carregando pedidos</S.Empty>
      ) : orders.length > 0 ? (
        <S.Orders>
          {orders.map((o) => (
            <S.Order key={o.id} onClick={() => handleEditOrder(o)}>
              <S.Left>
                <S.OrderHeader>
                  <h1>{o.customer.name}</h1>

                  <span>
                    {format(parseISO(o.createdAt), 'd MMM HH:mm', {
                      locale: ptBR
                    })}
                  </span>
                </S.OrderHeader>

                <S.OrderInformation>
                  {o.orderType === 'DELIVERY' ? (
                    <div>
                      <span>
                        <b>Entregar em:</b> {o.delivery.formattedAddress}
                      </span>

                      <span>
                        <b>Tipo da entrega:</b>{' '}
                        {o.delivery.deliveryType === 'NORMAL'
                          ? 'Normal'
                          : 'Agendada'}
                      </span>

                      {o.delivery.deliveryType !== 'NORMAL' && (
                        <span>
                          <b>Entregar:</b> {o.delivery.scheduledTo}
                        </span>
                      )}
                    </div>
                  ) : (
                    o.orderType === 'TAKEOUT' && (
                      <div>
                        <span>Para retirar</span>
                      </div>
                    )
                  )}
                </S.OrderInformation>

                <S.Footer>
                  <span>
                    {o.items.length} {o.items.length > 1 ? 'items' : 'item'}
                  </span>
                  <span>{formatPrice(o.total.orderAmount)}</span>
                </S.Footer>
              </S.Left>

              <S.Right>
                <div>
                  <span>{o.index}</span>
                </div>
              </S.Right>
            </S.Order>
          ))}
        </S.Orders>
      ) : (
        <S.Empty>Nenhum pedido encontrado</S.Empty>
      )}

      {order && (
        <OrderModal
          handleEditOrder={handleEditOrder}
          isOpen={isOpen}
          order={order}
          setOrder={setOrder}
        />
      )}
    </S.Container>
  )
}

export default OrdersTable
