import styled from 'styled-components'

export const Container = styled.div`
  overflow-x: auto;

  table {
    border-collapse: collapse;
    width: 100%;

    th,
    tbody tr > td {
      padding: 1rem;
      vertical-align: middle;
      text-align: center;
    }

    tbody {
      width: 100%;
    }

    tbody tr {
      border-bottom: 1px solid #eceef2;
      background: #f8f8f8;
    }

    tbody tr:nth-last-of-type(even) {
      background: #fdfdfd;
    }

    tbody tr .actions a,
    tbody tr .actions button {
      cursor: pointer;
      background: #ffffff;
      border-radius: 0.5rem;
      padding: 0.5rem;

      svg {
        color: #000000;
        width: 1.5rem;
        height: 1.5rem;
      }

      &:hover {
        background: #17a2b8;

        svg {
          color: #ffffff;
        }
      }
    }

    tbody tr .actions {
      text-align: -webkit-center;
    }

    tbody tr .actions td:not(:first-child) {
      padding-left: 1rem;
    }
  }
`
