import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  .rti--container {
    padding: 1rem;
    width: 100%;
    font-size: 1.5rem;
    min-height: 3.5rem;
    box-shadow: 1px 1px 5px 0 #e9e9e9;
    outline: none;
    line-break: anywhere;

    background: #fff;
    border: 1px solid #ccc;

    &:focus {
      border-color: #20a8d8;
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &.is-invalid .rti--container {
    border-color: ${(props) => props.theme.colors.red};
  }
`
