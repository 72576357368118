import { format } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { zonedTimeToUtc } from 'date-fns-tz'

import { OrderProps } from 'utils/props'
import { numberBrFormatter } from 'utils/utils'
import { baseURL } from 'services/api'

export const useOrderPrint = (order: OrderProps) => {
  let html = `<!DOCTYPE html>

  <html>
  
  <head>
      <meta charset="utf-8">
  
      <meta name="author" content="FastMercado">
      <meta name="copyright" content="© FastMercado">
  
      <meta name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no">
      <meta name="robots" content="noindex, nofollow">
      <meta name="language" property="language" content="pt-br">
      <meta name="content-language" property="content-language" content="pt-br">
      <meta name="msapplication-config" content="none">
  
      <meta http-equiv="cache-control" content="no-cache">
      <meta http-equiv="pragma" content="no-cache">
      <meta http-equiv="expires" content="0">
  
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta http-equiv="content-type" content="text/html; charset=UTF-8">
      <meta http-equiv="content-script-type" content="text/javascript">
      <meta http-equiv="content-style-type" content="text/css">
      <meta http-equiv="imagetoolbar" content="no">
  
      <title>Impressão - FastMercado</title>
  
      <link rel="shortcut icon" type="image/x-icon"
          href="/favicon.png" />
      <link href="//static.sitemercado.com.br/files/font-awesome-4.7.0/css/font-awesome.min.css" async rel="stylesheet"
          type="text/css" />
      <style>
          body {
              background: rgb(204, 204, 204);
              font-family: sans-serif;
              font-size: 12px;
          }
  
          page[size="A4"] {
              background: white;
              width: 21cm;
              /*height: 29.7cm;*/
              display: block;
              margin: 0 auto;
              margin-bottom: 0.5cm;
              /*box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);*/
              padding: 20px;
          }
  
  
          .border {
              border-bottom: 1px solid #999;
              border-left: 1px solid #999;
              border-top: 1px solid #ccc;
              border-right: 1px solid #ccc;
          }
  
          .tab-default tr td {
              border: 1px solid red;
          }
  
          table {
              /*margin: 1px 0px;*/
              padding: 0px;
          }
  
          table tr td {
              margin: 0px 0px;
              /*padding: 1px;*/
          }
  
          .tab-dados-pedido {
              margin: 0px 0px !important;
              padding: 0px !important;
          }
  
          .tab-dados-pedido tr td {
              margin: 0px 0px !important;
              padding: 1px !important;
          }
  
          hr {
              border: none;
              border-top: 1px solid #999;
          }
  
          .area-destaque {
              text-decoration: none;
              background-color: #fff;
              border: 5px solid #444;
              padding: 5px;
              border-radius: 10px;
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
          }
  
          fieldset {
              border: none;
              /*border-top: 1px solid #ccc;*/
              padding: 0px;
              margin: 6px 0px 0px 0px;
          }
  
          fieldset legend {
              background-color: #fff;
              padding: 0px 5px 0px 0px;
              margin: 0px;
              text-transform: uppercase;
              font-size: 13px;
              font-weight: 700;
          }
  
          .label {
              /*font-weight: 700;*/
              width: 50px;
          }
  
          .fl {
              float: left;
          }
  
          .fr {
              float: right;
          }
  
          cb {
              clear: both;
          }
  
          .header-produto {
              font-size: 9px;
          }
  
          .header-produto td {
              font-size: 9px;
              border-bottom: 1px solid #999;
              padding: 3px;
          }
  
          .header-produto1 {
              font-size: 9px;
          }
  
          .header-produto1 td {
              font-weight: normal;
              font-size: 9px;
          }
  
          .content-produto {
              font-size: 9px;
          }
  
          .content-produto td {
              /*height: 38px;
              font-size: 12px;
              border-bottom: 1px solid #999;
              border-left: 1px solid #999;
              padding: 2px;
              margin: -1px;*/
              height: 50px;
              font-size: 12px;
              border-bottom: 1px solid #999;
              border-left: 1px solid #999;
              padding: 2px 2px 2px 6px;
              margin: 0px;
          }
  
          .content-produto td del {
              color: #777;
          }
  
          .content-produto2 td {
              height: 40px !important;
              border-bottom: 1px solid #999 !important;
              border-left: 1px solid #999 !important;
              border-right: 1px solid #999 !important;
          }
  
          .content-produto-bg1 td {
              background-color: #f9f9f9;
              height: 40px;
              border-bottom: 1px solid #dedede;
          }
  
          .content-produto-bg2 td {
              background-color: #f4f4f4;
              height: 40px;
              border-bottom: 1px solid #bfbfbf;
          }
  
          .content-produto-added td {
              background-color: #e9ffda;
              height: 40px;
          }
  
          table {
              page-break-inside: auto !important;
          }
  
          tr {
              page-break-inside: avoid !important;
              page-break-after: auto !important;
          }
  
          thead {
              display: table-header-group !important;
          }
  
          tfoot {
              display: table-footer-group !important;
          }
  
  
          @media print {
  
              body,
              page[size="A4"] {
                  margin: 0;
                  -webkit-print-color-adjust: exact;
              }
  
              .hide-print {
                  display: none !important;
              }
          }
  
  
          .tab-discount-price {
              padding: 0px;
              margin: 10px 0px;
              font-size: 12px;
              /*box-shadow: 0px 5px 10px rgba(0,0,0,0.3);*/
              border-radius: 10px;
          }
  
          .tab-discount-price .tab-discount-price-header td {
              background-color: #eaeaea;
              color: #000;
              text-transform: uppercase;
              font-weight: 700;
              padding: 6px;
              border: 1px solid #000000;
          }
  
          .tab-discount-price .tab-discount-price-header .tab-discount-price-rowspan {
              background-color: #ffffff;
              font-weight: 700;
              padding: 2px;
              font-size: 28px;
              color: #6d9e10;
              border: 1px solid #fff;
          }
  
          .tab-discount-price .tab-discount-price-header sup {
              font-size: 10px;
              color: rgba(0, 0, 0, 0.5);
          }
  
          .tab-discount-price .tab-discount-price-header .tab-discount-price-rowspan .fa-plus {
              color: #0078ff;
              font-size: 18px;
          }
  
          .tab-discount-price .tab-discount-price-header .tab-discount-price-rowspan .fa-minus {
              color: #e00202;
              font-size: 18px;
          }
  
          .tab-discount-price .tab-discount-price-body td {
              background-color: #fff;
              padding: 6px;
              border: 1px solid #000000;
          }
  
          .tab-discount-price tr .bgblue-header {
              background-color: #bad6f7;
          }
  
          .tab-discount-price tr .bgblue-body {
              background-color: #e1efff;
          }
  
          .tab-discount-price tr .bgred-header {
              background-color: #ffb9b9;
          }
  
          .tab-discount-price tr .bgred-body {
              background-color: #ffe4e4;
          }
  
          .tab-discount-price tr .bggreen-header {
              background-color: #cee2a8;
          }
  
          .tab-discount-price tr .bggreen-body {
              background-color: #e7efda;
              color: green;
              font-size: 17px;
              font-weight: 700;
          }
  
          .msg-discount-price {
              background-color: #f4f4f4;
          }
  
          .msg-discount-price td {
              padding: 10px;
              border: 1px solid #000000;
          }
  
          .msg-discount-price td span {
              font-size: 20px;
          }
  
          .msg-discount-price td small {
              font-size: 12px;
              color: #555;
          }
  
          .msg-discount-price td small a {
              color: #0078ff;
              font-weight: 700;
          }
  
          .anticipation-item-name-img {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 5px;
              border: 1px dashed black;
              border-right: none;
          }
  
          .anticipation-item-middle-border {
              border: 1px #212529;
              border-style: dashed none;
          }
  
          .anticipation-item-right-border {
              border: 1px dashed black;
              border-left: none;
          }
  
          .tag-anticipation-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 55px;
              height: 27px;
              border: 1px solid #212529;
              box-sizing: border-box;
              border-radius: 2px;
          }
  
          .tag-anticipation-item-span {
              font-weight: bold;
              font-size: 9px;
              display: flex;
              align-items: center;
              letter-spacing: 0.005em;
              text-transform: uppercase;
              color: #212529;
          }
      </style>
  </head>
  
  <body>
      <page size="A4">
          <table width="100%" cellpadding="0" cellspacing="0" border="0">
              <tr class="print-primary">
                  <td colspan="2" width="100%"></td>
              </tr>
              <tr class="print-primary">
                  <td align="left" style="width: 62%">
                      <div style="float:left;">
                          <img width="100px" src="https://fastmercado.com.br/static/media/logo.76cbeec5.png" alt="" />
                      </div>
                  </td>
              </tr>
              <tr class="print-primary">
                  <td align="left">
                      <div style="font-size:12px; font-weight:normal;">Fast Mercado - <b>Fast Mercado</b> - (73)
                          99977-1819 - Teixeira de Freitas/BA</div>
                  </td>
              </tr>
              <tr class="print-primary">
                  <td align="left" colspan="2">
                      <table width="100%" cellpadding="0" cellspacing="0" border="0" class="tab-dados-pedido">
                          <tr class="print-primary">
                              <td width="75%" valign="middle" align="left" class="border">
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                      <tr class="print-primary">
                                          <td width="50%">
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                  <tr class="print-primary">
                                                      <td valign="top" class="label">Loja:</td>
                                                      <td valign="top" align="left"><b>Fast Mercado</b></td>
                                                  </tr>
                                              </table>
                                          </td>
                                      </tr>
                                  </table>
                                  <table width="100%" cellpadding="0" cellspacing="0">
                                      <tr class="print-primary">
                                          <td width="50%">
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                  <tr class="print-primary">
                                                      <td valign="top" class="label">Cliente:</td>
                                                      <td valign="top" align="left"><b>${
                                                        order.customer.name
                                                      }</b></td>
                                                  </tr>
                                              </table>
                                          </td>
                                      </tr>
                                  </table>
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                      <tr class="print-primary">
                                          <td width="50%">
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                  <tr class="print-primary">
                                                      <td valign="top" class="label">CPF:</td>
                                                      <td valign="top"><b>${
                                                        order.customer.document
                                                      }</b> <small
                                                              style="color:red; font-weight:bold;">CPF NA NOTA</small>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </td>
                                          <td width="50%">
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                  <tr class="print-primary">
                                                      <td valign="top" class="label">Telefone:</td>
                                                      <td valign="top">
                                                          <b>
                                                          ${
                                                            order.customer
                                                              .phoneNumber
                                                          }
                                                          </b>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </td>
                                      </tr>
                                  </table>
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                      <tr class="print-primary">
                                          <td width="100%">
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                  <tr class="print-primary">
                                                      <td valign="top" class="label">Endereço:</td>
                                                      <td valign="top">
                                                          <b>
                                                          ${
                                                            order.delivery
                                                              .streetName
                                                          }
  
                                                              - Nº ${
                                                                order.delivery
                                                                  .streetNumber
                                                              }
  
                                                              - Bairro: ${
                                                                order.delivery
                                                                  .neighborhood
                                                              }
  
  
                                                              - Referência: ${
                                                                order.delivery
                                                                  .reference
                                                              }
                                                          </b>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </td>
                                      </tr>
                                  </table>
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                      <tr class="print-primary">
                                          <td width="100%">
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                  <tr class="print-primary">
                                                      <td valign="top" class="label">Pagamento:</td>
                                                      <td valign="top">
                                                          <span>${order.payments.methods
                                                            .map(
                                                              (method) =>
                                                                method.method
                                                            )
                                                            .join(
                                                              (method) =>
                                                                `${method}`
                                                            )}</span>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
  
              <tr class="print-primary">
                  <td>
                      <br />
                  </td>
              </tr>
  
              <tr>
                  <td colspan="2">
                      <table id="table-produtos" width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tr class="header-produto">
                              <td align="center" valign="middle" style="width:40px;" class="product-img-size">
                                  <b>FOTO</b>
                              </td>
                              <td align="center" valign="middle" style="width:350px;">
                                  <b>PRODUTO</b>
                              </td>
                              <td align="center" valign="middle" style="width:60px;">
                                  <b>VALOR</b>
                              </td>
                              <td align="center" valign="middle" style="width:55px;">
                                  <b>ESTOQUE</b>
                              </td>
                              <td align="center" valign="middle" style="width:55px;">
                                  <b>COD. BARRA</b>
                              </td>
                              <td align="center" valign="middle" style="width:55px;">
                                  <b>QTD SOLICITADO</b>
                              </td>
                              <td align="center" valign="middle" style="width:55px;">
                                  <b>QTD ALTERADO</b>
                              </td>
                              <td align="center" valign="middle" style="width:60px;">
                                  <b>ENCONTRADO</b>
                              </td>
                              <td align="center" valign="middle" style="width:70px;">
                                  <b>NÃO<br />ENCONTRADO</b>
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
              <tr>
                  <td colspan="2">
                      <table id="table-produtos" width="100%" cellpadding="0" cellspacing="0" border="0">`

  order.items.forEach((item) => {
    const quantity =
      item.unitType === 'KG'
        ? item.quantity < 1
          ? `${String(item.quantity.toFixed(2)).substring(2, 4)}0G`
          : `${item.quantity.toFixed(2).replace('.', ',')}Kg`
        : item.quantity

    html += `
      <tr class="content-produto-bg1 ">
        <td align="center" valign="middle" style="width:40px;" class="product-img-size">
            <img src="${`${baseURL}/product/${item.id}/image`}"
                style="width:40px;" />
        </td>
        <td align="center" valign="middle" style="width:270px;">
            <span style="font-size:14px;">
                ${item.name}
                <br />
            </span>
        </td>
        <td align="center" valign="middle" style="width:60px;">
            <small style="font-size:9px;">R$</small>
            ${numberBrFormatter(item.priceSale)}
        </td>
        <td align="center" valign="middle" style="width:55px;">
            ${item.stock.stock}
            <small style="font-size:9px;">${item.unitType}.</small>
        </td>
        <td align="center" valign="middle" style="width:55px;">
            <span>
                <small style="font-size:11px">${item.sku.substr(0, 8)}</small>
                <br />
                <b style="font-size:15px">${item.sku.substr(8)}</b>
            </span>
        </td>
        <td align="center" valign="middle" style="width:55px;">
            <span style="font-size:14px; font-weight:bold;">${quantity}</span>
        </td>
        <td align="center" valign="middle" style="width:60px;">
            <span style="font-size:14px; font-weight:bold;">${quantity}</span>
        </td>
        <td align="center" valign="middle" style="width:60px;">
            <div style="border: 1px solid black; padding:6px;">
                &nbsp;
            </div>
        </td>
        <td align="center" valign="middle" style="width:70px;">
            <div style="border: 1px solid black; width:15px; height:15px;">
                &nbsp;
            </div>
        </td>
    </tr>`
  })

  html += `</table>
                  </td>
              </tr>
  
  
              <tr>
                  <td><br /></td>
              </tr>
  
              <tr class="print-primary">
                  <td align="left" colspan="2">
                      <div style="float:left; width:50%;" class="print-primary">
                          <table width="100%" cellpadding="0" cellspacing="2" border="0" class="tab-discount-price">
                              <tr class="tab-discount-price-header print-primary">
                                  <td align="center" class="bggreen-header">
                                      Valor Estimado
                                  </td>
                              </tr>
                              <tr class="msg-discount-price print-primary">
                                  <td colspan="17" valign="middle" align="center">
                                      <small>
                                          R$ ${numberBrFormatter(
                                            order.total.orderAmount
                                          )}
                                      </small>
                                  </td>
                              </tr>
                          </table>
                      </div>
                      <div style="float:left; width:50%;" class="print-primary">
                          <table width="100%" cellpadding="0" cellspacing="2" border="0" class="tab-discount-price">
                              <tr class="tab-discount-price-header print-primary">
                                  <td align="center" class="bggreen-header">
                                      Valor Final <small>(após separação)</small>
                                  </td>
                              </tr>
                              <tr class="msg-discount-price print-primary">
                                  <td colspan="17" valign="middle" align="center">
                                      <small>
                                          R$ ${numberBrFormatter(
                                            order.total.orderAmount
                                          )}
                                      </small>
                                  </td>
                              </tr>
                          </table>
                      </div>
                      <div style="clear:both;" class="print-primary"></div>
                  </td>
              </tr>
  
              <tr>
                  <td><br /></td>
              </tr>
              <tr>
                  <td colspan="2" style="border-bottom:1px dashed black; padding:5px;"></td>
              </tr>
              <tr>
                  <td colspan="2" style="padding:5px;"></td>
              </tr>
  
              <tr class="print-primary">
                  <td align="left" colspan="2" style="padding:0px; margin:0px;">
                      <table width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tr>
                              <td align="left" valign="middle" width="28%" style="padding:0px; margin:0px;">
                                  Nome do separador
                                  <div style="border:1px solid #000; padding:15px 0px; width:97%;"></div>
                              </td>
                              <td align="left" valign="middle" width="27%" style="padding:0px; margin:0px;">
                                  Qtd. volumes na área seca / resfriada
                                  <div style="border:1px solid #000; padding:15px 10px; width:38%; float:left;"></div>
                                  <div
                                      style="border:1px solid #000; padding:15px 10px; width:37%; float:left; margin-left:5px;">
                                  </div>
                              </td>
                              <td align="left" valign="middle" width="10%" style="padding:0px; margin:0px;">
                                  Nº Caixa
                                  <div style="border:1px solid #000; padding:15px 0px; width:92%;"></div>
                              </td>
                              <td align="left" valign="middle" width="15%" style="padding:0px; margin:0px;">
                                  Nº Cupom Fiscal
                                  <div style="border:1px solid #000; padding:15px 0px; width:94%;"></div>
                              </td>
                              <td align="left" valign="middle" width="20%" style="padding:0px; margin:0px;">
                                  Valor Cupom Fiscal (R$)
                                  <div style="border:1px solid #000; padding:15px 0px; width:100%;"></div>
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
              <tr class="print-primary">
                  <td align="left" colspan="2" style="padding:0px; margin:0px;">
                      <table width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tr>
                              <td align="left" valign="middle" width="28%" style="padding-top:10px; margin:0px;">
                                  Identificação dos volumes
                                  <div style="border:1px solid #000; padding:15px 0px; width:100%;"></div>
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
              <tr class="print-primary">
                  <td colspan="2" style="border-bottom:1px dashed black; padding:10px;"></td>
              </tr>
  
              <tr class="print-primary">
                  <td colspan="2" style="padding:5px;"></td>
              </tr>
              <tr class="print-primary">
                  <td align="left" colspan="2"
                      style="padding-top:0px; padding-bottom:25px; border-bottom:1px dashed black;">
                      <h3>
                          Recebimento: Declaro ter conferido e estou de acordo com as mercadorias recebidas.
                      </h3>
                      <small style="font-size:12px;">
                          <span>Cliente: ${order.customer.name}</span>
                          <span>- CPF: ${order.customer.document}</span>
                          <span>
                              - Telefone: ${order.customer.phoneNumber}
                          </span>
                      </small>
                      <br />
                      <br />
                      <small style="font-size:12px;">
                          ENTREGA EM:
                          ${order.delivery.streetName}
                          - Nº ${order.delivery.streetNumber}
                          - Bairro: ${order.delivery.neighborhood}
                          - Referência: ${
                            order.delivery.reference
                          } - Cidade: Teixeira de Freitas/BA
                      </small>
                      <br />
                      <br />
                      <small style="font-size:12px;">
                          Pedido realizado em ${format(
                            zonedTimeToUtc(
                              order.createdAt,
                              'America/Sao_Paulo'
                            ),
                            "dd'/'MM'/'yyyy 'às' HH:mm",
                            { locale: pt }
                          )}
                      </small>
                      <br />
                      <br />
                      <table width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tr class="print-primary">
                              <td align="left" valign="top" width="25%">
                                  Nome Completo Legível
                                  <div style="border:1px solid #000; padding:15px 0px; width:98%;">
                                  </div>
                              </td>
                              <td align="left" valign="top" width="12%">
                                  Documento (RG/CPF/CNH)
                                  <div style="border:1px solid #000; padding:15px 0px; width:97%;">
                                  </div>
                              </td>
                              <td align="left" valign="top" width="10%">
                                  Data/Hora
                                  <div style="border:1px solid #000; padding:15px 0px; width:96%;">
                                  </div>
                              </td>
                              <td align="left" valign="top" width="12%">
                                  Cupom Fiscal / Nota Fiscal
                                  <div style="border:1px solid #000; padding:15px 0px; width:95%;">
                                  </div>
                              </td>
                          </tr>
                      </table>
                      <br />
                      <table width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tr class="print-primary">
                              <td align="left" valign="top" width="40%">
                                  Forma de Pagamento
                                  <div style="border:1px solid #000; padding:15px 0px; width:98%;">
                                  </div>
                              </td>
                              <td align="left" valign="top" width="35%">
                                  Assinatura
                                  <div style="border:1px solid #000; padding:15px 0px; width:98%;">
                                  </div>
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
  
          </table>
      </page>
  </body>
  
  </html>`

  return {
    html
  }
}
