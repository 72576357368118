import Swal from 'sweetalert2'
import { logout } from './auth'

const errorHandler = (err) => {
  const error = err?.response?.data

  if (error?.plan || error?.subscription || error?.upgradePlan) {
    if (error?.upgradePlan) {
      Swal.fire({
        icon: 'info',
        title: error.message,
        showConfirmButton: true
      }).then(() => {
        window.location.replace('/stores/user-profile')
      })

      return Promise.reject(err.response)
    }

    if (error?.plan && !error?.isNewUser) {
      Swal.fire({
        icon: 'info',
        title: 'Você precisa atualizar seu plano!',
        showConfirmButton: true
      }).then(() => {
        window.location.replace('/subscribe/plans')
      })

      return Promise.reject(err.response)
    }

    if (error?.plan && error?.isNewUser) {
      Swal.fire({
        icon: 'info',
        title: 'Selecione um plano para continuar',
        text: error.message,
        showConfirmButton: true
      }).then(() => {
        window.location.replace('/subscribe/plans')
      })

      return Promise.reject(err.response)
    }

    if (error?.subscription) {
      Swal.fire({
        icon: 'error',
        title: 'Oops... Houve um Erro!',
        text: error?.message,
        showConfirmButton: true
      })
      return Promise.reject(err.response)
    }
  }

  if (
    err.message === 'Network Error' ||
    err.message === 'Request failed with status code 401'
  ) {
    logout()
    // window.location.href = ''
    return Promise.reject()
  }

  if (err.message === 'Network Error') {
    logout()

    window.location.href = 'https://painel.fastmercado.com.br'
  }

  if (error?.message === 'Não existe um serviço com esse id') {
    return Promise.reject(err.response)
  }

  Swal.fire({
    icon: 'error',
    title: 'Oops... Houve um Erro!',
    text: error?.message,
    showConfirmButton: true
  })

  return Promise.reject()
}

export default errorHandler
