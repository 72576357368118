import React, { useState } from 'react'

import InputComponent from 'components/Input'

import * as S from './styles'
import type { CategoryProps } from '../..'
import { FiFolderMinus, FiFolderPlus } from 'react-icons/fi'

interface Props {
  categories: string[]
  category: CategoryProps
  handleSelectCategory: (categoryId: string) => void
}

const Category: React.FC<Props> = ({
  categories,
  category,
  handleSelectCategory
}) => {
  const [showSubCategories, setShowSubCategories] = useState(false)

  const handleChangeShowSubCategories = () => {
    setShowSubCategories((prevState) => !prevState)
  }

  return (
    <S.Container nesting={category.nesting}>
      <S.Category>
        <S.ShowSubcategories>
          {Boolean(category.subCategories.length) && (
            <button type="button" onClick={handleChangeShowSubCategories}>
              {showSubCategories ? (
                <FiFolderMinus color="#17a2b8" />
              ) : (
                <FiFolderPlus color="#17a2b8" />
              )}
            </button>
          )}
        </S.ShowSubcategories>

        <InputComponent
          type="checkbox"
          width="1rem"
          name={category.id}
          id={category.id}
          checked={categories.some((c) => c === category.id)}
          onClick={() => handleSelectCategory(category.id)}
        />
        <label htmlFor={category.id}>{category.name}</label>
      </S.Category>

      {showSubCategories &&
        category.subCategories.map((subCategory) => (
          <Category
            key={subCategory.id}
            categories={categories}
            category={subCategory}
            handleSelectCategory={handleSelectCategory}
          />
        ))}
    </S.Container>
  )
}

export default Category
