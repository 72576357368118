import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useMemo
} from 'react'
import {
  FiBox,
  FiChevronLeft,
  FiChevronRight,
  FiEye,
  FiTrash2
} from 'react-icons/fi'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { debounce } from '@material-ui/core'

import InputComponent from 'components/Input'
import TableComponent from 'components/TableComponent'

import { apiv2, baseURL } from 'services/api'
import { ProductProps } from 'utils/props'
import { formatPrice } from 'utils/utils'
import { useToast } from 'contexts/ToastContext'

import * as S from './styles'

type StatusProps = '' | 'AVAILABLE' | 'UNAVAILABLE'

interface ProductCategoryAndTag
  extends Omit<ProductProps, 'categories' | 'tags'> {
  categories: {
    categoryId: string
    productId: string
    category: {
      id: string
      name: string
      categoryId: string
      status: 'AVAILABLE' | 'UNAVAILABLE'
      createdAt: string
      updatedAt: string
    }
  }[]
  tags: {
    tagId: string
    productId: string
    tag: {
      id: string
      name: string
      status: 'AVAILABLE' | 'UNAVAILABLE'
      createdAt: string
      updatedAt: string
    }
  }[]
  stock: {
    id: string
    sku: string
    quantity: number
    updatedAt: string
  }
}

const Products: React.FC = () => {
  const { showToast } = useToast()

  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [status, setStatus] = useState<StatusProps>('')
  const [search, setSearch] = useState('')

  const [products, setProducts] = useState<ProductCategoryAndTag[]>([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [totalProductsAvaiable, setTotalProductsAvaiable] = useState(0)
  const [totalProductsUnavailable, setTotalProductsUnavailable] = useState(0)

  const loadProductsData = useCallback(async () => {
    setIsLoading(true)

    try {
      const { headers, data } = await apiv2.get(
        `product?page=${currentPage}&status=${status}&search=${search}`
      )
      setProducts(data.products)
      setTotalProducts(data.totalProducts)
      setTotalProductsAvaiable(data.totalProductsAvaiable)
      setTotalProductsUnavailable(data.totalProductsUnavailable)
      setTotalPages(parseInt(headers.pagesize))
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }, [currentPage, search, status])

  useEffect(() => {
    loadProductsData()
  }, [loadProductsData])

  const handleChangeStatus = useMemo(
    () =>
      debounce((status: StatusProps) => {
        setCurrentPage(1)
        setStatus(status)
      }, 250),
    []
  )

  const handleChangeSearch = useMemo(
    () =>
      debounce(
        (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
        500
      ),
    []
  )

  const handleDeleteProduct = (product: ProductCategoryAndTag) => {
    Swal.fire({
      title: `Isto irá excluir o produto "${product.name}". Deseja continuar?`,
      icon: 'question',
      showCancelButton: true,
      reverseButtons: false,
      confirmButtonColor: '#17a2b8',
      cancelButtonColor: '#EA1D2C',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status } = await apiv2.delete(`product/${product.id}`)
        if (status === 200) {
          showToast({
            type: 'success',
            message: 'Produto deletado com sucesso!'
          })
          loadProductsData()
        }
      }
    })
  }

  const handleChangeCurrentPage = (currentPage: number) => {
    setCurrentPage(currentPage)
    window.scrollTo(0, 0)
  }

  const handleDecrementPage = () => {
    if (currentPage === 1) return

    handleChangeCurrentPage(currentPage - 1)
  }

  const handleIncrementPage = () => {
    if (currentPage === totalPages) return

    handleChangeCurrentPage(currentPage + 1)
  }

  return (
    <S.Container>
      <S.Header>
        <div>
          <button
            className={status === '' && 'active'}
            onClick={() => handleChangeStatus('')}
          >
            Todos ({totalProducts})
          </button>
          <button
            className={status === 'AVAILABLE' && 'active'}
            onClick={() => handleChangeStatus('AVAILABLE')}
          >
            <span>|</span> Ativos ({totalProductsAvaiable})
          </button>
          <button
            className={status === 'UNAVAILABLE' && 'active'}
            onClick={() => handleChangeStatus('UNAVAILABLE')}
          >
            <span>|</span> Desativados ({totalProductsUnavailable})
          </button>
        </div>
        <div>
          <Link to="/dashboard/produtos/novo" title="Adicionar novo">
            <FiBox /> Adicionar novo
          </Link>
        </div>
      </S.Header>

      <S.Content>
        <S.ProductsHeader>
          <div>
            <label htmlFor="search">Pesquisar:</label>
            <InputComponent
              type="search"
              id="search"
              name="search"
              onChange={handleChangeSearch}
            />
          </div>
        </S.ProductsHeader>

        <TableComponent>
          <table>
            <thead>
              <tr>
                <th>Imagem</th>
                <th>Nome</th>
                <th>Sku</th>
                <th>Status</th>
                <th>Quantidade</th>
                <th>Preço</th>
                <th>Categorias</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => {
                const estoqueAtivado =
                  !product.manageStock ||
                  !product.sku ||
                  (product.sku && product.stock.quantity > 0)

                return (
                  <tr key={product.id}>
                    <td>
                      <S.ProductImage
                        src={`${baseURL}/product/${product.id}/image`}
                        alt={product.name}
                        draggable={false}
                      />
                    </td>
                    <td>{product.name}</td>
                    <td>{product.sku ?? '-'}</td>
                    <td>
                      <S.ProductBadge
                        className={
                          product.status === 'AVAILABLE' ? 'GREEN' : 'BLUE'
                        }
                      >
                        {product.status === 'AVAILABLE'
                          ? 'Ativo'
                          : 'Desativado'}
                      </S.ProductBadge>
                    </td>
                    <td>
                      <S.ProductBadge
                        className={estoqueAtivado ? 'GREEN' : 'BLUE'}
                      >
                        {estoqueAtivado ? `Em estoque` : 'Fora de estoque'} (
                        {product?.stock?.quantity ?? 0})
                      </S.ProductBadge>
                    </td>
                    <td>{formatPrice(product.priceSale)}</td>
                    <td>
                      {product.categories.map((category, index) => {
                        const ultimo = product.categories.length - 1 === index
                        return (
                          <Fragment key={category.categoryId}>
                            <S.ProductCategory
                              to={`/dashboard/categorias/${category.categoryId}`}
                            >
                              {category.category.name}
                            </S.ProductCategory>
                            {ultimo ? ' ' : ', '}
                          </Fragment>
                        )
                      })}
                    </td>
                    <td className="actions">
                      <td>
                        <Link
                          to={`/dashboard/produtos/${product.id}`}
                          title="Visualizar"
                        >
                          <FiEye />
                        </Link>
                      </td>

                      <td>
                        <button
                          title="Excluir"
                          onClick={() => handleDeleteProduct(product)}
                        >
                          <FiTrash2 />
                        </button>
                      </td>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </TableComponent>

        <S.Footer>
          <div>
            <span>Página: </span>
            <select
              onChange={(e) =>
                handleChangeCurrentPage(parseInt(e.target.value))
              }
            >
              {Array(totalPages)
                .fill(0)
                .map((item, index) => {
                  if (index + 1 === currentPage) {
                    return (
                      <option key={index} value={index + 1} selected>
                        {index + 1}
                      </option>
                    )
                  } else {
                    return (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    )
                  }
                })}
            </select>
          </div>

          <div>
            <button type="button" onClick={handleDecrementPage}>
              <FiChevronLeft />
            </button>
            <button type="button" onClick={handleIncrementPage}>
              <FiChevronRight />
            </button>
          </div>
        </S.Footer>
      </S.Content>
    </S.Container>
  )
}

export default Products
