import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import { Howl } from 'howler'

import { OrderProps } from 'utils/props'
import { useMarket } from './MarketContext'
import { api } from 'services/api'
import { socket } from 'services/socket'

interface OrderContextData {
  isLoading: boolean
  period: {
    startDate: moment.Moment
    endDate: moment.Moment
  }
  setPeriod(period: { startDate: moment.Moment; endDate: moment.Moment }): void
  selectedDate: string
  setSelectedDate(date: string): void
  onDate({ startDate, endDate }): void

  orders: OrderProps[]
  setOrders(orders: OrderProps[]): void

  total: number
}

const OrderContext = createContext({} as OrderContextData)

const orderSound = new Howl({
  src: ['/audio/orders.mp3'],
  volume: 1
})

const OrderProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  const { market } = useMarket()

  const audioRef = useRef<HTMLAudioElement>(null)
  const [isLoadingOrders, setIsLoadingOrders] = useState(true)
  const [period, setPeriod] = useState({
    startDate: moment.tz('America/Bahia'),
    endDate: moment.tz('America/Bahia')
  })
  const [selectedDate, setSelectedDate] = useState('today')
  const [orders, setOrders] = useState<OrderProps[]>([])

  const handleSound = async () => {
    const { data } = await api.get(`orders/${market.id}/count/placed`, {
      headers: {
        startDate: period.startDate.format('YYYY-MM-DD'),
        endDate: period.endDate.tz('America/Bahia').format('YYYY-MM-DD')
      }
    })

    if (data > 0) {
      orderSound.play()
    }
  }

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (market) {
      setIsLoading(false)

      if (isLoading) {
        document.getElementById('audio').click()

        setInterval(() => {
          if (document.getElementById('audio')) {
            document.getElementById('audio').click()
          }
        }, 5000)
      }
    }
  }, [isLoading, market])

  const total = useMemo(
    () =>
      orders
        .filter(
          (order) =>
            order.orderStatus !== 'PLACED' && order.orderStatus !== 'CANCELLED'
        )
        .reduce((totalAmount, i) => totalAmount + i.total.orderAmount, 0),
    [orders]
  )

  const onDate = useCallback(
    ({ startDate, endDate }) => {
      if (!startDate || !endDate) return

      if (!startDate.isValid() || !endDate.isValid()) return

      setPeriod({ startDate, endDate })
    },
    [setPeriod]
  )

  const loadOrders = useCallback(async () => {
    if (!pathname.includes('auth') && market) {
      setIsLoadingOrders(true)

      try {
        const { data } = await api.get<OrderProps[]>('orders', {
          headers: {
            marketId: market.id,
            startDate: period.startDate.format('YYYY-MM-DD'),
            endDate: period.endDate.format('YYYY-MM-DD')
          }
        })

        console.log(data)

        const newData = data.map((order, index, array) => ({
          ...order,
          index: array.length - index
        }))

        setOrders(newData)
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoadingOrders(false)
      }
    }
  }, [pathname, market, period.startDate, period.endDate])

  useEffect(() => {
    loadOrders()
  }, [loadOrders])

  useEffect(() => {
    if (!pathname.includes('auth') && market) {
      socket.on('newOrder', (data: OrderProps) => {
        if (data.marketId === market.id) {
          setOrders((prevState) => [
            {
              ...data,
              index: prevState.length + 1
            },
            ...prevState
          ])
        }
      })
    }
  }, [market, pathname])

  return (
    <OrderContext.Provider
      value={{
        isLoading: isLoadingOrders,
        period,
        setPeriod,
        selectedDate,
        setSelectedDate,
        onDate,

        orders,
        setOrders,

        total
      }}
    >
      <button id="audio" style={{ display: 'none' }} onClick={handleSound} />

      <audio src="/audio/orders.mp3" ref={audioRef} autoPlay muted loop />

      {children}
    </OrderContext.Provider>
  )
}

function useOrder(): OrderContextData {
  const context = useContext(OrderContext)

  if (!context) {
    throw new Error('useOrder must be used within a OrderContext')
  }

  return context
}

export { OrderContext, OrderProvider, useOrder }
