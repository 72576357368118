import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import InputComponent from 'components/Input'

import { useToast } from 'contexts/ToastContext'
import { useMarket } from 'contexts/MarketContext'
import { api } from 'services/api'

import * as S from './styles'

interface FormData {
  name: string
  slug: string
  email: string
  phone: string
  productsPerPage: number
}

const Market: React.FC = () => {
  const { showToast } = useToast()
  const { market, handleMarketUpdate } = useMarket()
  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object().shape({
    name: yup.string().required('Nome obrigatório'),
    slug: yup.string().required('Slug obrigatório'),
    email: yup.string().email('Email inválido').required('Email é obrigatório'),
    phone: yup.string().required('Telefone obrigatório'),
    productsPerPage: yup
      .number()
      .required('Quantidade de produtos por página obrigatório')
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (market) {
      setValue('name', market.name, { shouldValidate: true })
      setValue('slug', market.slug, { shouldValidate: true })
      setValue('email', market.email, { shouldValidate: true })
      setValue('phone', market.phone, { shouldValidate: true })
      setValue('productsPerPage', market.productsPerPage, {
        shouldValidate: true
      })
    }
  }, [setValue, market])

  const onSubmit = async (data: FormData) => {
    setIsLoading(true)

    try {
      const { data: marketData } = await api.put('markets', data)

      handleMarketUpdate(marketData)

      showToast({
        type: 'success',
        message: 'Mercado atualizado com sucesso'
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({
        type: 'error',
        message
      })
    } finally {
      setIsLoading(false)
    }
    // } else {
    //   const message = 'A validação do Recaptcha falhou.'
    //   showToast({ message, type: 'error' })
    // }
  }

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.Title>Configuração geral</S.Title>

      <S.Col>
        <InputComponent
          id="name"
          name="name"
          label="Nome da loja"
          className={errors.name && 'is-invalid'}
          {...register('name')}
        />
      </S.Col>

      {/* <S.Col>
        <S.Label htmlFor="title">Titulo da loja</S.Label>
        <InputComponent
          id="title"
          name="title"
          className={errors.title && 'is-invalid'}
          placeholder="Titulo da loja"
          {...register('title')}
        />
      </S.Col> */}

      <S.Col>
        <InputComponent
          id="slug"
          name="slug"
          label="Slug da loja"
          className={errors.slug && 'is-invalid'}
          {...register('slug')}
        />
      </S.Col>

      <S.Col>
        <InputComponent
          id="email"
          name="email"
          type="email"
          label="Email da loja"
          className={errors.email && 'is-invalid'}
          {...register('email')}
        />
      </S.Col>

      <S.Col>
        <InputComponent
          id="phone"
          name="phone"
          label="Telefone da loja"
          className={errors.phone && 'is-invalid'}
          {...register('phone')}
        />
      </S.Col>

      <S.Title>Visibilidade</S.Title>

      <S.Col>
        <InputComponent
          id="productsPerPage"
          name="productsPerPage"
          type="number"
          label="Produtos por página"
          className={errors.productsPerPage && 'is-invalid'}
          {...register('productsPerPage')}
        />
      </S.Col>

      <S.Button>Salvar</S.Button>
    </S.Container>
  )
}

export default Market
