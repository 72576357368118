import React from 'react'

import InputComponent from 'components/Input'
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'

import InputTagComponent from 'components/InputTagComponent'
import ImageInput from './ImageInput'

import type { CategoryProps, ProductDataProps } from '..'

import * as S from './styles'
import Category from './Category'

interface Props {
  register: UseFormRegister<ProductDataProps>
  setValue: UseFormSetValue<ProductDataProps>
  watch: UseFormWatch<ProductDataProps>
  errors: FieldErrors<ProductDataProps>
  categories: CategoryProps[]
}

const ProductContentRight: React.FC<Props> = ({
  setValue,
  watch,
  errors,
  categories: categoriesList
}) => {
  const categories = watch('categories')
  const tags = watch('tags')

  const handleSelectCategory = (categoryId: string) => {
    const hasCategory = categories.some((c) => c === categoryId)

    if (hasCategory) {
      setValue(
        'categories',
        categories.filter((c) => c !== categoryId)
      )
    } else {
      setValue('categories', [...categories, categoryId])
    }
  }

  return (
    <S.Container>
      <S.Col>
        <ImageInput setValue={setValue} watch={watch} />
      </S.Col>

      <S.Col>
        <S.Categories className={errors.categories && 'is-invalid'}>
          <header>
            <p>Categorias</p>
          </header>

          <div>
            {categoriesList.map((category) => (
              <Category
                key={category.id}
                categories={categories}
                category={category}
                handleSelectCategory={handleSelectCategory}
              />
            ))}
          </div>
        </S.Categories>
      </S.Col>

      <S.Col>
        <label htmlFor="tags">Tags</label>
        <InputTagComponent
          name="tags"
          value={tags}
          error={Boolean(errors.tags)}
          onChange={(t) => setValue('tags', t)}
        />
      </S.Col>
    </S.Container>
  )
}

export default ProductContentRight
