import React, { useState, useMemo, Fragment } from 'react'
import { Switch } from 'react-router-dom'

import routes from 'routes/menuRoutes'
import { useWindowSize } from 'hooks/useWindowSize'

import PrivateRoute from 'components/PrivateRouter'
import Sidebar from 'components/Sidebar'
import Navbar from './Navbar'

import * as S from './styles'

const Dashboard = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true)
  const [width] = useWindowSize()

  const handleOpenSidebar = () => {
    if (width > 768) {
      if (isSidebarExpanded) {
        setIsSidebarExpanded(false)
      } else {
        setIsSidebarExpanded(true)
      }
    } else {
      setIsSidebarOpen(true)
    }
  }

  const handleCloseSidebar = () => {
    if (width > 768) {
      setIsSidebarExpanded(false)
    } else {
      setIsSidebarOpen(false)
    }
  }

  const renderMenuRoutes = useMemo(
    () =>
      routes.map((prop) => {
        return (
          <PrivateRoute
            {...props}
            path={`/dashboard${prop.path}`}
            component={prop.component}
            key={prop.name}
            exact
          />
        )
      }),
    [props]
  )

  return (
    <Fragment>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        isSidebarExpanded={isSidebarExpanded}
        setIsSidebarExpanded={setIsSidebarExpanded}
        handleCloseSidebar={handleCloseSidebar}
      />

      <S.Container expanded={isSidebarExpanded}>
        <Navbar handleOpenSidebar={handleOpenSidebar} />

        <S.Content>
          <Switch>{renderMenuRoutes}</Switch>
        </S.Content>
      </S.Container>
    </Fragment>
  )
}

export default Dashboard
