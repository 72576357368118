import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: 0;

    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important;                 /*Firefox*/
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  *:focus {
    outline: 0;
  }

  a, a:visited {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  *, button, input {
    border: 0;
    outline: none;
    background: none;
    font-family: Roboto;
  }

  button {
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  html {
    font-family: ${(props) => props.theme.font.family};
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    background-color: ${(props) => props.theme.colors.mainBg};
    color: #777777;
    font-size: ${(props) => props.theme.font.sizes.small};
  }

  .DateInput_input {
    font-size: 0.875rem;
    color: #8898aa;
    width: 80%;
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding-right: 0rem;
  }

  .DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 6.8rem !important;
  }

  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #8898aa;
    height: 24px;
    width: 1rem;
  }

  .DateRangePickerInput__showClearDates {
    padding-right: 30px;
    border-radius: 6px;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #6cbe5f;
    border: 1px double #61b154;
    color: #fff;
  }

  .CalendarDay__selected_span {
    background: #89eb7a;
    border: 1px double #7dd56f;
    color: #fff;
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #89eb7a;
    border: 1px double #7dd56f;
    color: #fff;
  }

  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    margin-left: -4px;
  }

  .DateRangePicker_picker {
    z-index: 999999;
  }

  /* .DayPickerNavigation_button__default,
  .DayPickerNavigation_button__default:active {
    background: ${(props) => props.theme.colors.secondary};
    border: 1px double ${(props) => props.theme.colors.secondary};
  }

  .DayPickerNavigation_svg__horizontal {
    fill: ${(props) => props.theme.colors.text.cards};
  } */

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    display: table-cell;
    vertical-align: middle;

    /* background: ${(props) => props.theme.colors.green};
    border: 1px double ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.text.cards}; */
  }

  .CalendarDay__selected_span {
    display: table-cell;
    vertical-align: middle;

    /* background: ${(props) => props.theme.colors.green};
    border: 1px double ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.text.cards};
    opacity: 0.8; */
  }

  /* .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) => props.theme.colors.green};
    border: 1px double ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.text.cards};
    opacity: 1;
  }

  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    margin-left: -4px;
  }

  .DateRangePicker_picker {
    z-index: 999999;
  }

  .DayPicker,
  .CalendarMonthGrid,
  .CalendarMonth {
    background-color: ${(props) => props.theme.colors.primary};
  }

  .CalendarMonth_caption,
  .DayPicker_weekHeader_li {
    color: ${(props) => props.theme.colors.text.cards};
  } */

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    display: table-cell;
    vertical-align: middle;

    /* background-color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.text.cards};
    color: ${(props) => props.theme.colors.text.cards};
    opacity: 0.5; */
  }

  .CalendarDay__default {
    display: table-cell;
    vertical-align: middle;

    /* background-color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.text.cards};
    color: ${(props) => props.theme.colors.text.cards}; */
  }

  .popover {
    font-size: 1.3rem;
  }

  .custom-datepicker-tip .body {
    display: flex;
    flex-direction: column;
  }

  .custom-datepicker-tip .body button {
    border: none;
    background-color: initial;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0;
    color: #007eff;
    text-decoration: underline;
    cursor: pointer;
  }

  @media(max-width: 1080px) {
    html {
      font-size: 60.5%;
    }
  }

  @media(max-width: 720px) {
    html {
      font-size: 58.75%;
    }
  }

  .bg-gradient-danger{
    background: linear-gradient(87deg, #e02929, #e07529) !important
  }

  .bg-gradient-primary {
    background: linear-gradient(87deg, #7dd56f, #6fd58c) !important
  }

  .bg-orange {
    background-color: #ff6744 !important
  }

  .react-contextmenu {
    background-color: ${(props) => props.theme.colors.primary};
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    color: ${(props) => props.theme.colors.text.cards};
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }

  .react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: ${(props) => props.theme.colors.text.cards};
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #8257e6;
    border-color: #8257e6;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
  }

  .react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
  }
  
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  }

  .react-contextmenu-item.react-contextmenu-submenu
    > .react-contextmenu-item:after {
    content: '▶';
    display: inline-block;
    position: absolute;
    right: 7px;
  }

  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }
`

export default GlobalStyle
