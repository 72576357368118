import styled from 'styled-components'

export const Container = styled.form`
  width: 100%;
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  font-style: italic;

  color: #17a2b8;

  &:not(:first-child) {
    margin-top: 2rem;
  }
`

export const Col = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 1.5rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 1rem;
  }
`

export const Button = styled.button`
  margin: 2rem 0 0 auto;
  background: ${(props) => props.theme.colors.primary};
  color: #b0bec5;
  padding: 1.5rem 2rem;
  border: none;
  font-weight: bold;
  font-size: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #17a2b8;
    color: #ffffff;
  }
`
