import React, { useState } from 'react'

import * as S from './styles'

interface TabItemProps {
  icon?: JSX.Element
  name: string
  content: JSX.Element
}

interface Props {
  items: TabItemProps[]
}

const TabComponent: React.FC<Props> = ({ items }) => {
  const [itemSelected, setItemSelected] = useState(0)

  return (
    <S.Container>
      <S.Items>
        {items.map((item, index) => (
          <S.Item
            key={item.name}
            type="button"
            selected={itemSelected === index}
            onClick={() => setItemSelected(index)}
          >
            {item.icon}
            {item.name}
          </S.Item>
        ))}
      </S.Items>

      <S.Content>{items[itemSelected].content}</S.Content>
    </S.Container>
  )
}

export default TabComponent
