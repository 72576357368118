import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Col = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  margin-bottom: 1rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
`
