import React from 'react'
import { TagsInput, TagsInputProps } from 'react-tag-input-component'

import * as S from './styles'

const InputTagComponent: React.FC<TagsInputProps & { error?: boolean }> = ({
  ...rest
}) => {
  return (
    <S.Container className={rest.error && 'is-invalid'}>
      <TagsInput placeHolder="" separators={[',', 'Enter']} {...rest} />
    </S.Container>
  )
}

export default InputTagComponent
