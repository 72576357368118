import styled from 'styled-components'
import ReactSelect from 'react-select'

export const Container = styled(ReactSelect)`
  width: 100%;

  .react-select__control {
    width: 100%;
    font-size: 1.5rem;
    min-height: 3.5rem;
    box-shadow: 1px 1px 5px 0 #e9e9e9;
    outline: none;

    background-color: #fff;
    border: 1px solid #ccc;
  }

  .react-select__control--is-focused {
    border-color: #20a8d8 !important;
  }

  .react-select__placeholder.css-1wa3eu0-placeholder,
  .react-select__single-value.css-1uccc91-singleValue {
    color: #ffffff;
  }

  .react-select__multi-value__label,
  .react-select__option--is-focused {
    background: #17a2b8;
    color: #ffffff;
  }

  .react-select__multi-value {
    background: #17a2b8;
  }

  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    border-color: #e5e5e5;
    box-shadow: none;
  }
`
