import React, { Fragment, useCallback, useState } from 'react'
import { MdClose } from 'react-icons/md'
import 'react-responsive-modal/styles.css'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { OrderProps } from 'utils/props'
import { useOrder } from 'contexts/OrderContext'
import { api, apiv2, baseURL } from 'services/api'
import { useOrderPrint } from 'hooks/useOrderPrint'
import { formatPrice } from 'utils/utils'
import errorHandler from 'services/errorHandler'

import * as S from './styles'

interface Props {
  handleEditOrder(orderValue: any): void
  isOpen: boolean
  order: OrderProps
  setOrder(newState: OrderProps): void
}

const OrderModal: React.FC<Props> = ({
  handleEditOrder,
  isOpen,
  order,
  setOrder
}) => {
  const { orders, setOrders } = useOrder()
  const { html } = useOrderPrint(order)
  const [isLoading, setIsLoading] = useState(false)

  const handleToggle = useCallback((handleEditOrderFunction) => {
    handleEditOrderFunction()
  }, [])

  const handleExportOrder = useCallback(async () => {
    const { data } = await apiv2.post('order/export', {
      client: order.customer,
      address: order.delivery ?? null,
      products: order.items
    })
  }, [order.customer, order.delivery, order.items])

  const handlePrintOrder = useCallback(async () => {
    const date = new Date()
    const mSec = date.getTime()
    const mywindow = window.open(
      '',
      `FASTMERCADO_${mSec}`,
      'height=400,width=600'
    )

    mywindow.document.write(html)

    mywindow.document.close()
    mywindow.print()
    mywindow.focus()
    mywindow.onafterprint = () => {
      mywindow.close()
    }
  }, [html])

  const handleOrderStatus = useCallback(
    async (
      status:
        | 'PLACED'
        | 'CONFIRMED'
        | 'READ'
        | 'DISPATCHED'
        | 'CONCLUDED'
        | 'PENDING'
        | 'CANCELLED'
    ) => {
      setIsLoading(true)

      try {
        if (status === 'CANCELLED') {
          const { data } = await api.put(`/orders/${order.id}/canceled`)
        } else if (status === 'PLACED') {
          const { data } = await api.put(`/orders/${order.id}/placed`)
        } else if (status === 'CONFIRMED') {
          const { data } = await api.put(`/orders/${order.id}/confirm`)
        } else if (status === 'READ') {
          const { data } = await api.put(`/orders/${order.id}/readyToPickup`)
        } else if (status === 'DISPATCHED') {
          const { data } = await api.put(`/orders/${order.id}/dispatch`)
        } else if (status === 'CONCLUDED') {
          const { data } = await api.put(`/orders/${order.id}/concluded`)
        }

        setOrder({
          ...order,
          orderStatus: status
        })
        setOrders(
          orders.map((item) => {
            if (item.id === order.id) {
              return { ...item, orderStatus: status }
            }

            return item
          })
        )
        handleToggle(handleEditOrder)
      } catch (err) {
        errorHandler(err)
      } finally {
        setIsLoading(false)
      }
    },
    [handleEditOrder, handleToggle, order, orders, setOrder, setOrders]
  )

  return (
    <S.Container
      open={isOpen}
      showCloseIcon={false}
      onClose={() => handleToggle(handleEditOrder)}
      center
      styles={{
        modal: {
          padding: 0,
          maxWidth: 'unset'
        }
      }}
    >
      <S.Content>
        <S.Header>
          <div className="left">
            <div>
              <h1>
                Data{' '}
                {format(parseISO(order.createdAt), 'd MMM', {
                  locale: ptBR
                })}{' '}
                <span>
                  {format(parseISO(order.createdAt), 'HH:mm', {
                    locale: ptBR
                  })}
                </span>
              </h1>
            </div>
          </div>

          <div className="right">
            <button
              type="button"
              title="Fechar"
              onClick={() => handleToggle(handleEditOrder)}
            >
              <MdClose />
            </button>
          </div>
        </S.Header>

        <S.Grid>
          <S.Left>
            {order.orderStatus === 'CONCLUDED' && (
              <div>
                <S.Title>Informações do pedido</S.Title>

                <div className="info">
                  <div>
                    <h1>Entregue</h1>
                    <span>
                      {format(parseISO(order.createdAt), 'd MMM HH:mm', {
                        locale: ptBR
                      })}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <S.Customer>
              <S.Title>Cliente</S.Title>

              <div className="info">
                <div>
                  <h1>{order.customer.name}</h1>
                  <span>{order.customer.phoneNumber}</span>
                </div>
              </div>
            </S.Customer>

            {order.orderType === 'DELIVERY' && (
              <div>
                <S.Title>Endereço para entrega</S.Title>

                <div className="info">
                  <div>
                    <h1>
                      {order.delivery.streetName}, {order.delivery.streetNumber}{' '}
                      - {order.delivery.reference}
                    </h1>
                    <span>{order.delivery.neighborhood}</span>
                  </div>
                </div>
              </div>
            )}

            {order.observations?.trim().length > 0 && (
              <div>
                <S.Options>
                  <S.Option>
                    <div>
                      <p>Observação:</p>{' '}
                      <span>{order.observations?.trim()}</span>
                    </div>
                  </S.Option>
                </S.Options>
              </div>
            )}

            <div>
              <S.Title>Itens</S.Title>

              {order.items.map((item, index) => {
                const quantity =
                  item.unitType === 'KG'
                    ? item.quantity < 1
                      ? `${String(item.quantity.toFixed(2)).substring(2, 4)}0g`
                      : `${item.quantity.toFixed(2).replace('.', ',')}kg`
                    : `${item.quantity}un`

                return (
                  <S.Product key={index}>
                    <img
                      src={`${baseURL}/product/${item.id}/image`}
                      alt={item.name}
                    />
                    <h1>
                      {quantity} {item.name} {formatPrice(item.totalPrice)}
                    </h1>
                  </S.Product>
                )
              })}
            </div>

            <div>
              <S.Title>Formas de pagamentos</S.Title>

              <div>
                {order.payments.methods.map((method, i) => (
                  <Fragment key={i}>
                    <div className="forms">
                      <h1>{method.method}</h1>
                      <span>{formatPrice(method.value)}</span>
                    </div>

                    {method.change > 0 && (
                      <Fragment>
                        <div className="forms">
                          <small>Troco para</small>
                          <span>{formatPrice(method.change)}</span>
                        </div>
                        <div className="forms">
                          <small>Troco</small>
                          <span>
                            {formatPrice(method.change - method.value)}
                          </span>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>

            {/* {order.sambanetReference && (
              <div>
                <S.Title>Referencia Sambanet</S.Title>

                <div className="info">
                  <div>
                    <h1>
                      Venda com o ID: {order.sambanetReference.sambanetOrderId}
                    </h1>
                  </div>
                </div>
              </div>
            )} */}
          </S.Left>

          <S.Right>
            <div>
              <div>
                <span>Subtotal</span>
                <h1>{formatPrice(order.total.subtotal)}</h1>
              </div>
              <div className="green">
                <span>Tx. Entrega</span>
                <h1>{formatPrice(order.total.deliveryFee)}</h1>
              </div>
              <div className="red">
                <span>Desconto</span>
                <h1>{formatPrice(order.total.benefits)}</h1>
              </div>
              <div className="blue">
                <span>Total</span>
                <h1>{formatPrice(order.total.orderAmount)}</h1>
              </div>
            </div>

            <div>
              {/* {order.orderStatus !== 'PLACED' &&
                order.orderStatus !== 'CANCELLED' && ( */}
              {/* <button
                type="button"
                className="border-blue"
                onClick={handleExportOrder}
              >
                Exportar para o PDV
              </button> */}
              {/* )} */}

              <button type="button" className="blue" onClick={handlePrintOrder}>
                Imprimir
              </button>

              {order.orderStatus === 'PLACED' ? (
                <button
                  type="button"
                  className="red"
                  onClick={() => handleOrderStatus('CANCELLED')}
                  disabled={isLoading}
                >
                  Cancelar
                </button>
              ) : order.orderStatus === 'CONFIRMED' ? (
                <button
                  type="button"
                  className="red"
                  onClick={() => handleOrderStatus('PLACED')}
                  disabled={isLoading}
                >
                  Voltar para aguardando
                </button>
              ) : order.orderStatus === 'READ' ? (
                <button
                  type="button"
                  className="red"
                  onClick={() => handleOrderStatus('CONFIRMED')}
                  disabled={isLoading}
                >
                  Voltar para preparando
                </button>
              ) : order.orderStatus === 'DISPATCHED' ? (
                <button
                  type="button"
                  className="red"
                  onClick={() => handleOrderStatus('READ')}
                  disabled={isLoading}
                >
                  Voltar para pronto
                </button>
              ) : order.orderStatus === 'CONCLUDED' ? (
                <button
                  type="button"
                  className="red"
                  onClick={() =>
                    handleOrderStatus(
                      order.orderType === 'DELIVERY' ? 'DISPATCHED' : 'READ'
                    )
                  }
                  disabled={isLoading}
                >
                  {order.orderType === 'DELIVERY'
                    ? 'Voltar para entregando'
                    : 'Voltar para pronto'}
                </button>
              ) : (
                order.orderStatus === 'CANCELLED' && (
                  <button
                    type="button"
                    className="red"
                    onClick={() => handleOrderStatus('PLACED')}
                    disabled={isLoading}
                  >
                    Voltar para aguardando
                  </button>
                )
              )}

              {order.orderStatus === 'PLACED' ? (
                <button
                  type="button"
                  className="green"
                  onClick={() => handleOrderStatus('CONFIRMED')}
                  disabled={isLoading}
                >
                  Aceitar
                </button>
              ) : order.orderStatus === 'CONFIRMED' ? (
                <button
                  type="button"
                  className="green"
                  onClick={() => handleOrderStatus('READ')}
                  disabled={isLoading}
                >
                  Pronto
                </button>
              ) : order.orderStatus === 'READ' ? (
                <button
                  type="button"
                  className="green"
                  onClick={() =>
                    handleOrderStatus(
                      order.orderType === 'DELIVERY'
                        ? 'DISPATCHED'
                        : 'CONCLUDED'
                    )
                  }
                  disabled={isLoading}
                >
                  {order.orderType === 'DELIVERY'
                    ? 'Sair para entrega'
                    : 'Entregue'}
                </button>
              ) : (
                order.orderStatus === 'DISPATCHED' && (
                  <button
                    type="button"
                    className="green"
                    onClick={() => handleOrderStatus('CONCLUDED')}
                    disabled={isLoading}
                  >
                    Entregue
                  </button>
                )
              )}
            </div>
          </S.Right>
        </S.Grid>
      </S.Content>
    </S.Container>
  )
}

export default OrderModal
