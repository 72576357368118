import React from 'react'

import TabComponent from 'components/TabComponent'
import Market from './Market'
import Location from './Location'
import Getnet from './Getnet'
import Sambanet from './Sambanet'

const items = [
  {
    name: 'Loja',
    content: <Market />
  },
  {
    name: 'Localização',
    content: <Location />
  },
  {
    name: 'Getnet',
    content: <Getnet />
  },
  {
    name: 'Sambanet',
    content: <Sambanet />
  }
]

const Content: React.FC = () => {
  return <TabComponent items={items} />
}

export default Content
