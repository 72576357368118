import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import media from 'styled-media-query'

export const Container = styled(Modal)``

export const Content = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  gap: 10rem;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 1.5rem 2rem;

  background: ${(props) => props.theme.colors.primary};
  border-bottom: 2px solid ${(props) => props.theme.colors.secondary};

  ${media.lessThan('medium')`
    gap: 2rem;
    align-items: flex-start;
  `};

  .left {
    display: flex;
    gap: 5rem;
    align-items: center;

    ${media.lessThan('medium')`
      gap: 2rem;
      flex-direction: column;
      align-items: flex-start;
    `};

    > div {
      display: flex;
      gap: 4rem;
      align-items: center;

      ${media.lessThan('medium')`
        gap: 1rem;
        flex-direction: column;
        align-items: flex-start;
      `};

      h1 {
        display: flex;
        flex-direction: column;

        font-weight: 500;
        font-size: 1.5rem;

        color: #ffffff;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    button svg {
      width: 3rem;
      height: 3rem;

      color: #ffffff;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  gap: 5rem;
  grid-template-columns: 1fr 20rem;

  padding: 1.5rem 2rem;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `};
`

export const Left = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  > div {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    .info {
      display: flex;
      gap: 1.5rem;
      align-items: center;

      img {
        width: 3rem;
        height: 3rem;
      }

      h1 {
        font-weight: 400;
        font-size: 2rem;

        color: #777777;
      }

      span {
        font-weight: 400;
        font-size: 1.8rem;

        color: #777777;

        opacity: 0.8;
      }
    }

    .forms {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1,
      span {
        font-weight: normal;
        font-size: 1.8rem;

        color: #777777;
      }

      h1 {
        text-transform: uppercase;
      }
    }
  }
`

export const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 700;
  font-style: italic;

  color: #17a2b8;
`

export const Customer = styled.section`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  .info {
    display: flex;
    gap: 1.5rem;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
    }

    h1 {
      font-weight: 400;
      font-size: 2rem;

      color: #777777;
    }

    span {
      font-weight: 400;
      font-size: 1.8rem;

      color: #777777;

      opacity: 0.8;
    }
  }
`

export const Product = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;

  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;

  padding: 1rem;

  img {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
  }

  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;

    color: #777777;
  }
`

export const Options = styled.div`
  > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;

    margin-left: 1.5rem;

    color: #777777;

    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
`

export const Option = styled.div`
  display: flex;
  align-items: center;

  margin-left: 2rem;

  white-space: normal;

  p,
  span {
    display: inline-block;
    white-space: pre-line;
  }

  p {
    font-style: normal;
    font-size: 1.8rem;

    color: #777777;
    margin: 0 0.5rem 0 0;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;

    color: ${(props) => props.theme.colors.blue};
  }

  span:not(:last-child)::after {
    content: '+';
    margin: 0 0.5rem;
  }
`

export const Right = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;

  > div {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      min-height: 4.5rem;
      height: 100%;

      background: #1a1a1a;
      border-radius: 0.5rem;

      padding: 1rem;

      span,
      h1 {
        font-size: 1.8rem;
        font-weight: normal;
        color: #ffffff;
      }

      h1 {
        font-weight: 500;
      }

      &.green {
        h1 {
          color: ${(props) => props.theme.colors.green};
        }
      }

      &.red {
        h1 {
          color: ${(props) => props.theme.colors.red};
        }
      }

      &.blue {
        h1 {
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }

    > button {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
      min-height: 4.5rem;

      background: ${(props) => props.theme.colors.secondary};
      border-radius: 0.5rem;

      padding: 1rem;

      font-size: 1.8rem;
      font-weight: 700;

      color: #ffffff;

      &.green {
        background: ${(props) => props.theme.colors.green};
      }

      &.red {
        background: ${(props) => props.theme.colors.red};
      }

      &.blue {
        background: ${(props) => props.theme.colors.blue};
      }

      &.border-blue {
        background: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.blue};
        font-weight: 500;
        color: ${(props) => props.theme.colors.blue};
      }
    }
  }
`
