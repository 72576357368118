import React, { useCallback } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { FiX } from 'react-icons/fi'

import { baseURL } from 'services/api'
import type { ProductDataProps } from '../..'

import * as S from './styles'

interface Props {
  setValue: UseFormSetValue<ProductDataProps>
  watch: UseFormWatch<ProductDataProps>
}

const ImageInput: React.FC<Props> = ({ setValue, watch }) => {
  const image = watch('image')

  const handleChangeImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0]

      if (!file) return

      const toBase64 = (file: File) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })

      toBase64(file).then((base64: string) => {
        const base64String = base64.replace('data:', '').replace(/^.+,/, '')
        setValue('image', base64String)
      })
    },
    [setValue]
  )

  const handleRemoveImage = useCallback(() => {
    setValue('image', null)
  }, [setValue])

  return (
    <S.Container>
      <label htmlFor="logo">
        {image ? (
          <img src={`data:image/png;base64, ${image}`} alt="Imagem" />
        ) : (
          <img src={`${baseURL}/images/placeholder.png`} alt="Imagem" />
        )}

        <input
          type="file"
          id="logo"
          accept="image/*"
          data-file={image}
          onChange={handleChangeImage}
        />
      </label>

      {image && (
        <button type="button" onClick={handleRemoveImage}>
          <FiX />
        </button>
      )}
    </S.Container>
  )
}

export default ImageInput
