import styled from 'styled-components'
import DrawerRC from 'rc-drawer'

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  height: auto;
  min-height: 100vh;

  background: ${(props) => props.theme.colors.primary};
`

export const Drawer = styled(DrawerRC)`
  div.drawer-content-wrapper {
    div.drawer-content {
      ::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
`

export const Info = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 2rem;

  img {
    width: 12rem;
    height: 12rem;

    border-radius: 50%;
  }

  h1 {
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    max-width: 16rem;

    color: ${(props) => props.theme.colors.text.primary};
  }
`

export const List = styled.li`
  list-style: none;

  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  width: 100%;

  > li {
    display: flex;
    flex-direction: column;
  }
`

interface ItemProps {
  active?: boolean
}

export const Item = styled.ul<ItemProps>`
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;

  padding: 1.5rem;

  background: ${(props) => (props.active ? '#17a2b8' : 'none')};

  svg {
    color: #ffffff;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;

    color: ${(props) => props.theme.colors.text.primary};
  }
`
