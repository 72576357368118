import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 2px #ccc;
  padding: 1.5rem;

  > h1 {
    font-weight: 700;
    font-size: 2rem;
    font-style: italic;

    color: #17a2b8;
  }

  > div {
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
  }
`

export const CountOrders = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 3rem;
  height: 3rem;

  border-radius: 2rem;
  border: 2px solid #17a2b8;

  span {
    font-weight: bold;
    font-size: 1.5rem;

    color: #17a2b8;
  }
`

export const Orders = styled.main`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export const Order = styled.button`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;

  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 2px #ccc;

  padding: 1.5rem;
`

export const Left = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: space-between;

  width: 80%;
  height: 100%;
`

export const Right = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 3rem;
    height: 3rem;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 3rem;
    height: 3rem;

    border-radius: 2rem;
    border: 2px solid #17a2b8;

    span {
      font-weight: bold;
      font-size: 1.5rem;

      color: #17a2b8;
    }
  }
`

export const OrderHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  h1 {
    font-weight: bold;
    font-size: 1.6rem;
    text-transform: uppercase;

    color: #f86c6b;
  }

  span {
    font-weight: bold;
    font-size: 1.2rem;

    color: #17a2b8;
  }
`

export const OrderInformation = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;

    b {
      color: #e83e8c;
    }

    span {
      font-weight: bold;
      font-size: 1.3rem;
      text-align: start;

      color: #777777;
    }
  }
`

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;

  span {
    font-weight: bold;
    font-size: 1.3rem;
    text-align: start;

    color: #777777;
  }
`

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30rem;

  font-size: 2.5rem;
  font-weight: bold;
`
