import axios from 'axios'
import { getToken } from './auth'
import errorHandler from './errorHandler'

// export const baseURL = 'http://localhost:3333'
export const baseURL = 'https://apiv2.fastmercado.com.br'

const api = axios.create({
  baseURL: 'https://api.fastmercado.com.br',
  withCredentials: true
})
const apiv2 = axios.create({
  baseURL: `${baseURL}/dashboard`
})

api.interceptors.request.use((config) => {
  config.headers.Authorization = getToken()

  return config
})

api.interceptors.response.use(undefined, (err) => errorHandler(err))

export { api, apiv2 }
