import styled from 'styled-components'
import media from 'styled-media-query'

interface MainProps {
  expanded: boolean
}

export const Container = styled.main<MainProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: calc(100% - ${(props) => (props.expanded ? '240px' : '64px')});
  height: 100%;

  transition: all 0.15s;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;

  @media (max-width: 768px) {
    margin-left: 0;

    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 2rem;

  ${media.lessThan('medium')`
    min-height: 100vh;
  `}
`
