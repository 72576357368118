import styled, { css } from 'styled-components'

interface ContainerProps {
  nesting: number
}

export const Container = styled.div<ContainerProps>`
  ${(props) =>
    props.nesting &&
    css`
      margin-left: ${props.nesting}rem;
    `}
`

export const Category = styled.div`
  display: flex;
  margin-bottom: 1rem;

  input {
    margin-right: 1rem;
  }
`

export const ShowSubcategories = styled.div`
  margin-right: 1rem;
  width: 1rem;
`
