import styled from 'styled-components'

export const Container = styled.form`
  width: 100%;
`

export const Content = styled.section`
  display: flex;
  gap: 2rem;
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 2px #ccc;
  padding: 1.5rem;

  margin-bottom: 2rem;

  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 1rem;
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-top: 2rem;

  a button {
    background: ${(props) => props.theme.colors.primary};
    margin-right: 1rem;
  }

  button {
    padding: 1rem 2rem;
    color: #ffffff;
    background: #17a2b8;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 1.8rem;
  }
`
