import React, { useState, useCallback, useEffect } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { api } from 'services/api'
import { useMarket } from 'contexts/MarketContext'

import * as S from './styles'

const PagesTable: React.FC = () => {
  const { market } = useMarket()

  const [isLoading, setIsLoading] = useState(true)
  const currentPages = 16
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState([])

  const handleCreatePage = useCallback(async () => {
    setIsLoading(true)

    // try {
    //   const { data } = await api.put(`markets`, {
    //     pages: [
    //       {
    //         id: 1,
    //         name: 'Açougue',
    //         slug: 'acougue',
    //         categoryId: '4078',
    //         image:
    //           'https://bighiper.vtexassets.com/assets/vtex.file-manager-graphql/images/f44d6f30-b2a6-4b6f-969f-cbc9ea0fcf59___c37a948c192c116cac4ce37abf1219b2.png'
    //       },
    //       {
    //         id: 2,
    //         name: 'Bebidas',
    //         slug: 'bebidas',
    //         categoryId: '4964',
    //         image:
    //           'https://bighiper.vtexassets.com/assets/vtex.file-manager-graphql/images/b7b016a8-341a-44fd-a8a1-ba83ce4fbff0___42969548cd38c44890b17b0cd5ffd310.png'
    //       },
    //       {
    //         id: 3,
    //         name: 'Laticínios',
    //         slug: 'laticinios',
    //         categoryId: '5595',
    //         image:
    //           'https://bighiper.vtexassets.com/assets/vtex.file-manager-graphql/images/d7e85b95-ac74-46cf-81d2-52bf331c6312___c10084f06cc9c892b136b7fa614a587a.png'
    //       },
    //       {
    //         id: 4,
    //         name: 'Limpeza',
    //         slug: 'limpeza',
    //         categoryId: '5139',
    //         image:
    //           'https://bighiper.vtexassets.com/assets/vtex.file-manager-graphql/images/9af54eca-0ff9-4084-a88f-f161d08b9636___8d6eef84d51a752e32903e0cce3ba51d.png'
    //       },
    //       {
    //         id: 5,
    //         name: 'Mercearia',
    //         slug: 'mercearia',
    //         categoryId: '',
    //         image:
    //           'https://bighiper.vtexassets.com/assets/vtex.file-manager-graphql/images/9ea8e539-dcad-485a-b0a9-8f408d4ebbcd___b10224d29de140007192c0c5da5bc994.png'
    //       },
    //       {
    //         id: 6,
    //         name: 'Perfumaria',
    //         slug: 'perfumaria',
    //         categoryId: '',
    //         image:
    //           'https://bighiper.vtexassets.com/assets/vtex.file-manager-graphql/images/3c2b0954-b667-431d-b852-998e0bbaeda1___d8506beeef39bcc77f9db34d7fa2de63.png'
    //       }
    //     ]
    //   })

    //   console.log(data)
    // } catch (err) {
    //   console.log(err)
    // } finally {
    //   setIsLoading(false)
    // }
  }, [])

  const handleChangeCurrentPage = (currentPage: number) =>
    setCurrentPage(currentPage)

  const handleDecrementPage = () => {
    if (currentPage === 1) return

    handleChangeCurrentPage(currentPage - 1)
  }

  const handleIncrementPage = () => {
    if (currentPage === currentPages) return

    handleChangeCurrentPage(currentPage + 1)
  }

  return (
    <S.Container>
      <S.Header>
        <h1>Listado de páginas</h1>

        <div>
          <span>{market?.pages?.length}</span>
        </div>
      </S.Header>

      <S.ButtonCreatePage type="button" onClick={handleCreatePage}>
        Adicionar nova página
      </S.ButtonCreatePage>

      {market?.pages?.length > 0 ? (
        <>
          <S.Pages>
            {market?.pages.map((p) => (
              <S.Page key={p.id}>
                <S.Left>
                  <S.PageImage src={p.image} alt={p.name} draggable={false} />
                  <S.PageHeader>
                    <h1>{p.name}</h1>
                    <p>{p.slug}</p>
                  </S.PageHeader>
                </S.Left>

                <S.Right>
                  <S.Button type="button">Editar</S.Button>
                  <S.Button type="button">Excluir</S.Button>
                </S.Right>
              </S.Page>
            ))}
          </S.Pages>

          <S.Footer>
            <div>
              <span>Página: </span>
              <select
                onChange={(e) =>
                  handleChangeCurrentPage(parseInt(e.target.value))
                }
              >
                {Array(currentPages)
                  .fill(0)
                  .map((item, index) => {
                    if (index + 1 === currentPage) {
                      return (
                        <option key={index} value={index + 1} selected>
                          {index + 1}
                        </option>
                      )
                    } else {
                      return (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      )
                    }
                  })}
              </select>
            </div>

            <div>
              <button type="button" onClick={handleDecrementPage}>
                <FiChevronLeft />
              </button>
              <button type="button" onClick={handleIncrementPage}>
                <FiChevronRight />
              </button>
            </div>
          </S.Footer>
        </>
      ) : (
        <S.Empty>Nenhuma página encontrada</S.Empty>
      )}
    </S.Container>
  )
}

export default PagesTable
