import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;

  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 2px #ccc;
  padding: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column;

    div:first-child {
      margin-bottom: 1rem;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  button {
    position: relative;
    font-size: 1.5rem;
    color: #17a2b8;

    &.active {
      color: #666666;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: #666666;
    padding: 0 0.5rem;
  }

  a {
    display: flex;
    align-items: center;

    padding: 0.5rem 1rem;
    color: #ffffff;
    background: #17a2b8;
    border-radius: 0.5rem;
    text-align: center;

    svg {
      margin-right: 1rem;
    }
  }
`

export const Content = styled.section`
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 2px #ccc;
  padding: 1.5rem;
`

export const ProductsHeader = styled.header`
  display: flex;
  justify-content: flex-end;

  margin-bottom: 2rem;

  @media (max-width: 768px) {
    justify-content: center;
  }

  div {
    display: flex;
    align-items: center;

    label {
      margin-right: 2rem;
    }
  }
`

export const ProductImage = styled.img`
  width: 5rem;
  height: 5rem;
`

export const ProductBadge = styled.div`
  border-radius: 0.5rem;
  padding: 0.2rem 1rem;
  color: #ffffff;
  font-size: 1.5rem;
  white-space: nowrap;

  &.GREEN {
    background: #20c997;
  }

  &.BLUE {
    background: #63c2de;
  }
`

export const ProductCategory = styled(Link)`
  color: #5b9a68;
  white-space: nowrap;
`

export const ItemHeader = styled.header`
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: bold;
    font-size: 1.6rem;
    max-width: 200px;

    color: #17a2b8;
  }

  p {
    font-weight: bold;
    font-size: 1.2rem;

    color: #f86c6b;
  }

  span {
    font-weight: bold;
    font-size: 1.2rem;

    color: #777777;
  }
`

export const Footer = styled.footer`
  display: flex;
  gap: 3rem;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;

  > div {
    span,
    select,
    option {
      font-style: normal;
      font-weight: normal;
      font-size: 1.8rem;

      color: #888888;
    }
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;

    color: #888888;
  }
`

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30rem;

  font-size: 2.5rem;
  font-weight: bold;
`
