import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

interface MarketContextData {
  isLoading: boolean
  market: any
  setMarket(market: any): void

  handleMarketUpdate(data: any): void
}

const MarketContext = createContext({} as MarketContextData)

const MarketProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [market, setMarket] = useState(null)

  const handleLoadMarketData = useCallback(() => {
    const market = localStorage.getItem('@MARKET')

    if (market) {
      setMarket(JSON.parse(market))
    }
  }, [])

  useEffect(() => {
    handleLoadMarketData()
  }, [handleLoadMarketData])

  const handleMarketUpdate = (data) => {
    setMarket(data)
    localStorage.setItem('@MARKET', JSON.stringify(data))
  }

  return (
    <MarketContext.Provider
      value={{
        isLoading,
        market,
        setMarket,

        handleMarketUpdate
      }}
    >
      {children}
    </MarketContext.Provider>
  )
}

function useMarket() {
  const context = useContext(MarketContext)

  if (!context) {
    throw new Error('useMarket must be used within a MarketContext')
  }

  return context
}

export { MarketContext, MarketProvider, useMarket }
