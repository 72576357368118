import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 2px #ccc;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Items = styled.div`
  display: flex;
  gap: 0.1rem;
  flex-direction: column;

  max-width: 26rem;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

interface ItemProps {
  selected: boolean
}

export const Item = styled.button<ItemProps>`
  overflow: hidden;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => (props.selected ? '#17a2b8' : '#ffffff')};

  padding: 1rem;

  width: 100%;
  min-width: 26rem;

  svg {
    margin-right: 1rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  padding: 2rem;
`
