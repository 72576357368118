import styled from 'styled-components'

export const Container = styled.main`
  .d-none {
    display: none;
  }

  @media (min-width: 768px) {
    .d-md-block {
      display: block !important;
    }
  }

  .sidenav---sidenav---_2tBP {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1006;
    min-width: 64px;
    background: ${(props) => props.theme.colors.primary};
    -webkit-transition: min-width 0.2s;
    -moz-transition: min-width 0.2s;
    -o-transition: min-width 0.2s;
    -ms-transition: min-width 0.2s;
    transition: min-width 0.2s;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 6rem;

      box-shadow: 0 8px 6px -6px rgb(0 0 0 / 50%);

      img {
        width: 4rem;
        height: 4rem;
        object-fit: cover;

        border-radius: 50%;
      }

      span {
        display: none;

        color: #ffffff;
        margin-left: 1rem;
      }
    }

    .sidenav---sidenav-toggle---1KRjR {
      display: none;
    }

    &.sidenav---collapsed---LQDEv
      .sidenav---sidenav-nav---3tvij
      .sidenav---sidenav-navitem---uwIJ- {
      .sidenav---sidenav-subnav---1EN61 {
        display: none;
      }

      &:hover > .sidenav---sidenav-subnav---1EN61 {
        display: block;
      }
    }

    &.sidenav---expanded---1KdUL {
      min-width: 240px;

      .logo span {
        display: initial;
      }

      .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
        .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
          visibility: visible;
          opacity: 1;
          -ms-filter: none;
          filter: none;
          -webkit-transition: opacity 0.2s linear;
          -moz-transition: opacity 0.2s linear;
          -o-transition: opacity 0.2s linear;
          -ms-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
        }

        &.sidenav---expandable---3_dr7 > .sidenav---navitem---9uL5T {
          cursor: pointer;
        }
      }

      .sidenav---sidenav-subnav---1EN61 {
        position: static;
        border: none;
        float: left;
        clear: both;
        width: 100%;
        background: transparent;
        overflow: hidden;
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      .sidenav---sidenav-nav---3tvij
        > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL
        .sidenav---sidenav-subnav---1EN61 {
        display: block;
      }

      .sidenav---sidenav-subnav---1EN61 {
        display: none;

        > .sidenav---sidenav-subnavitem---1cD47 {
          position: relative;

          &::before {
            content: ' ';
            width: 100%;
            height: 28px;
            position: absolute;
            top: 0;
            z-index: -1;
          }

          &:first-child {
            display: none;
          }

          &:hover::before {
            background: #eeeeee;
            opacity: 0.15;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=15)';
            filter: alpha(opacity=15);
          }

          &::before {
            content: ' ';
            width: 100%;
            height: 28px;
            position: absolute;
            top: 0;
            z-index: -1;
          }

          &.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
            color: #eeeeee;

            &::before {
              content: ' ';
              width: 2px;
              height: 20px;
              left: 10px;
              top: 4px;
              position: absolute;
              border-left: 2px #eeeeee solid;
            }
          }

          &:hover > .sidenav---navitem---9uL5T {
            background: transparent;
            color: #eeeeee;
          }

          > .sidenav---navitem---9uL5T {
            color: #eeeeee;
            padding: 0 14px 0 20px;
            line-height: 28px;
          }
        }
      }
    }
  }

  .sidenav---sidenav-toggle---1KRjR {
    position: relative;
    // float: left;
    width: 64px;
    height: 64px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    background-image: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    .sidenav---icon-bar---u1f02 {
      display: block;
      width: 20px;
      height: 2px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 1px;
      -webkit-transition: all 0.15s;
      -moz-transition: all 0.15s;
      -o-transition: all 0.15s;
      -ms-transition: all 0.15s;
      transition: all 0.15s;
      opacity: 0.7;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
      filter: alpha(opacity=70);

      + .sidenav---icon-bar---u1f02 {
        margin-top: 4px;
      }
    }
  }

  .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
    .sidenav---sidenav-toggle---1KRjR
    .sidenav---icon-bar---u1f02 {
    width: 25px;

    &:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      opacity: 0;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      filter: alpha(opacity=0);
    }

    &:nth-child(3) {
      margin-top: -8px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .sidenav---sidenav-nav---3tvij {
    float: left;
    padding: 0;
    margin: 0;
    clear: both;
    list-style: none;
    width: 100%;

    .sidenav---sidenav-navitem---uwIJ- {
      margin-top: 0.5rem;
    }

    .sidenav---sidenav-navitem---uwIJ-:first-child {
      margin-top: 2rem;
    }

    > .sidenav---sidenav-navitem---uwIJ- {
      clear: both;
      position: relative;

      &.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
        cursor: default;
      }

      &:hover > .sidenav---navitem---9uL5T::after {
        background: #1a1a1a;
        opacity: 0.15;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=15)';
        filter: alpha(opacity=15);
      }

      &.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after,
      &:hover.sidenav---highlighted---oUx9u
        > .sidenav---navitem---9uL5T::after {
        background: #17a2b8;
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
        filter: alpha(opacity=20);
      }

      &.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL
        > .sidenav---navitem---9uL5T::after,
      &:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL
        > .sidenav---navitem---9uL5T::after {
        background: #000;
        opacity: 0.25;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=25)';
        filter: alpha(opacity=25);
      }

      &.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL
        > .sidenav---navitem---9uL5T::after {
        background: #000;
        opacity: 0.2;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
        filter: alpha(opacity=20);
      }

      &:hover {
        &.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL
          > .sidenav---navitem---9uL5T::after {
          background: #000;
          opacity: 0.2;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
          filter: alpha(opacity=20);
        }

        > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
      }

      &.sidenav---highlighted---oUx9u
        > .sidenav---navitem---9uL5T
        .sidenav---navicon---3gCRo {
        opacity: 1;
        -ms-filter: none;
        filter: none;
      }

      &:hover > .sidenav---navitem---9uL5T {
        .sidenav---navicon---3gCRo,
        .sidenav---navtext---1AE_f {
          color: #eeeeee;
        }
      }

      &.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
        .sidenav---navicon---3gCRo,
        .sidenav---navtext---1AE_f {
          color: #eeeeee;
        }
      }

      &:hover > .sidenav---navitem---9uL5T {
        .sidenav---navicon---3gCRo > *,
        .sidenav---navtext---1AE_f > * {
          color: #eeeeee;
        }
      }

      &.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
        .sidenav---navicon---3gCRo > *,
        .sidenav---navtext---1AE_f > * {
          color: #eeeeee;
        }
      }

      > .sidenav---navitem---9uL5T {
        position: relative;
        display: block;
        line-height: 40px;
        height: 40px;
        white-space: nowrap;
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        cursor: pointer;

        &:focus {
          outline: 0;
        }

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          background: #fff;
          opacity: 0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
          filter: alpha(opacity=0);
          z-index: -1;
        }

        .sidenav---navicon---3gCRo,
        .sidenav---navtext---1AE_f,
        .sidenav---navicon---3gCRo > *,
        .sidenav---navtext---1AE_f > * {
          color: #ababab;
        }

        .sidenav---navicon---3gCRo {
          display: block;
          float: left;
          width: 64px;
          height: 40px;
          margin-right: -6px;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: transparent;
          opacity: 0.7;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
          filter: alpha(opacity=70);
          line-height: 50px;
          text-align: center;

          + .sidenav---navtext---1AE_f {
            width: 0;
            visibility: hidden;
            opacity: 0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
            filter: alpha(opacity=0);
            -webkit-transition: visibility 0s 0.2s, opacity 0.2s linear;
            -moz-transition: visibility 0s 0.2s, opacity 0.2s linear;
            -o-transition: visibility 0s 0.2s, opacity 0.2s linear;
            -ms-transition: visibility 0s 0.2s, opacity 0.2s linear;
            transition: visibility 0s 0.2s, opacity 0.2s linear;
          }
        }
      }
    }
  }

  .sidenav---sidenav-subnav---1EN61 {
    min-width: 200px;
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 0;
    left: 64px;
    background: #fff;
    border: 1px #bbb solid;
    -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);

    > .sidenav---sidenav-subnavitem---1cD47 {
      &:first-child {
        margin: 0 24px;
        line-height: 48px;
        border-bottom: 1px #ccc solid;
        font-size: 14px;
        font-weight: normal;
      }

      &:nth-child(2) {
        margin-top: 8px;
      }

      &:last-child {
        margin-bottom: 8px;
      }

      &.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
        color: #db3d44;
        font-weight: bold;
        background: none;
        cursor: default;

        &:hover {
          background: #fff;
        }
      }

      > .sidenav---navitem---9uL5T {
        display: block;
        text-decoration: none;
        color: #222;
        font-size: 13px;
        line-height: 30px;
        padding: 0 24px;
        cursor: pointer;

        &:hover {
          background: #eee;
        }

        &:focus {
          outline: 0;
        }
      }
    }
  }

  .sidenav---sidenav---_2tBP {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 20px;

    * {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;

      &:before,
      &:after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit;
      }
    }
  }

  .sidebar-body .custom-sidebar-icon img {
    width: 2rem;
    padding-top: 1rem;
  }

  .sidebar-body .custom-sidebar-text {
    display: flex;

    .new-tag {
      margin-left: 8px;
      margin-right: 12px;
    }
  }

  .sidebar-body .custom-sidebar-text .new-tag > span {
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 10px;
    border: 1px solid #eeeeee;
    color: #eeeeee;
  }
`
