import React from 'react'

import * as S from './styles'

interface Props {
  htmlFor?: string
}

const LabelComponent: React.FC<Props> = ({ children, htmlFor }) => {
  return <S.Container htmlFor={htmlFor}>{children}</S.Container>
}

export default LabelComponent
