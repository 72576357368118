import React from 'react'

import OrdersTable from './OrdersTable'

import * as S from './styles'

const Orders: React.FC = () => {
  return (
    <S.Container>
      <OrdersTable />
    </S.Container>
  )
}

export default Orders
