import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 2px #ccc;
  padding: 1.5rem;

  h1 {
    font-weight: 700;
    font-size: 2rem;
    font-style: italic;

    color: #17a2b8;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 3rem;
    height: 3rem;

    border-radius: 2rem;
    border: 2px solid #17a2b8;

    span {
      font-weight: bold;
      font-size: 1.5rem;

      color: #17a2b8;
    }
  }
`

export const ButtonCreatePage = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: max-content;
  height: 5rem;

  padding: 1.5rem;
  background: #17a2b8;
  border-radius: 1rem;

  font-size: 1.8rem;
  font-weight: bold;

  color: #ffffff;

  svg {
    margin-right: 1rem;
  }
`

export const Pages = styled.main`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export const Page = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;

  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 2px #ccc;

  padding: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Left = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  width: 80%;
  height: 100%;
`

export const Right = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`

export const PageImage = styled.img`
  width: 10rem;
  height: 10rem;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-height: 5rem;

  padding: 1.5rem;
  background: #17a2b8;
  border-radius: 1rem;

  font-size: 1.8rem;
  font-weight: bold;

  color: #ffffff;

  svg {
    margin-right: 1rem;
  }
`

export const PageHeader = styled.header`
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: bold;
    font-size: 1.6rem;
    max-width: 200px;

    color: #17a2b8;
  }

  p {
    font-weight: bold;
    font-size: 1.2rem;

    color: #f86c6b;
  }

  span {
    font-weight: bold;
    font-size: 1.2rem;

    color: #777777;
  }
`

export const Footer = styled.footer`
  display: flex;
  gap: 3rem;
  justify-content: flex-end;
  align-items: center;

  > div {
    span,
    select,
    option {
      font-style: normal;
      font-weight: normal;
      font-size: 1.8rem;

      color: #888888;
    }
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;

    color: #888888;
  }
`

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30rem;

  font-size: 2.5rem;
  font-weight: bold;
`
