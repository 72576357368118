import socketio from 'socket.io-client'

// const baseURL = 'http://10.0.0.104:3340'
const baseURL = 'https://api.fastmercado.com.br'

const socket = socketio(baseURL, {
  transports: ['websocket']
})

export { socket }
