import React from 'react'

import Content from './Content'

import * as S from './styles'

const Tools: React.FC = () => {
  return (
    <S.Container>
      <S.Header>
        <h1>Definições da Loja</h1>
      </S.Header>

      <Content />
    </S.Container>
  )
}

export default Tools
