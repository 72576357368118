export const TOKEN_KEY = process.env.REACT_APP_STAGE === 'qa' ? 'jwt' : 'twj'

interface ResponseProps {
  token: string
  market: object
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem('@MARKET')
  localStorage.removeItem('user')
  localStorage.removeItem('last-active-service-id')
}

export const login = (response: ResponseProps) => {
  localStorage.setItem(TOKEN_KEY, response.token)
  localStorage.setItem('@MARKET', JSON.stringify(response.market))
}

export const isAuthenticated = () => {
  const localData = localStorage.getItem(TOKEN_KEY)

  if (localData !== null) {
    return true
  }

  return false
}

export const getToken = () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`
