import styled from 'styled-components'

interface CartIconProps {
  color: string
}

export const Container = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 799px) {
    display: flex;
    flex-wrap: wrap;
  }
`

export const Card = styled.div`
  display: flex;

  width: 100%;
  min-height: 7.5rem;

  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 3px 2px #cccccc;
`

export const CardIcon = styled.div<CartIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 7.5rem;
  height: 100%;

  background: ${(props) => props.color};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  svg {
    color: #ffffff;
  }
`

export const CardContent = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;

  padding: 1rem;
`

export const CardValue = styled.p`
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;

  color: #21759b;
`

export const CardSummary = styled.span`
  font-size: 1.5rem;
  text-align: center;

  color: #717171;
`
