import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  min-height: 100vh;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;

  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 2px #ccc;
  padding: 1.5rem;

  > h1 {
    font-weight: 700;
    font-size: 2rem;
    font-style: italic;

    color: #17a2b8;
  }
`
