import Home from 'pages/Dashboard/Home'
import Pages from 'pages/Dashboard/Pages'
import Products from 'pages/Dashboard/Products'
import Product from 'pages/Dashboard/Product'
import Orders from 'pages/Dashboard/Orders'
import Tools from 'pages/Dashboard/Tools'

const menuRoutes = [
  {
    path: '/',
    name: 'Início',
    component: Home
  },
  {
    path: '/paginas',
    name: 'Páginas',
    component: Pages
  },
  {
    path: '/produtos',
    name: 'Produtos',
    component: Products
  },
  {
    path: '/produtos/novo',
    name: 'Produto',
    component: Product
  },
  {
    path: '/produtos/:productId',
    name: 'Produto',
    component: Product
  },
  {
    path: '/pedidos',
    name: 'Pedidos',
    component: Orders
  },
  {
    path: '/configuracoes',
    name: 'Configurações',
    component: Tools
  }
]

export default menuRoutes
