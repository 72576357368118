import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import jwt from 'jsonwebtoken'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import usePersistedState from 'hooks/usePersistedState'
import { Routes } from 'routes/router'
import { getToken, logout } from 'services/auth'

import dark from 'styles/themes/dark'
import GlobalStyle from 'styles/global'

const App = () => {
  const [lastSyncUser, setLastSyncUser] = usePersistedState(
    'syncUser',
    moment().format('DD-MM-YYYY')
  )

  const location = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [location])

  useEffect(() => {
    const authenticationToken = getToken()
    const [, token] = authenticationToken.split(' ')

    if (token && jwt.decode(token)) {
      const jwtExpirationDate = jwt.decode(token)
      const { exp } = jwtExpirationDate as {
        exp: number
      }

      if (Date.now() < exp) {
        logout()

        window.location.href = 'https://painel.fastmercado.com.br/auth/login/'
      }
    }
  })

  return (
    <ThemeProvider theme={dark}>
      <Routes />
      <GlobalStyle />
    </ThemeProvider>
  )
}

export default App
