import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import ProductContentLeft from './ContentLeft'
import ProductContentRight from './ContentRight'

import { apiv2 } from 'services/api'
import { ProductProps } from 'utils/props'
import { useToast } from 'contexts/ToastContext'

import * as S from './styles'

export interface CategoryProps {
  id: string
  name: string
  status: 'AVAILABLE' | 'UNAVAILABLE'
  categoryId: string
  createdAt: string
  updatedAt: string

  nesting: number
  subCategories: CategoryProps[]
}

export interface ProductDataProps extends ProductProps {
  tags: string[]
  categories: string[]
}

const Product = () => {
  const { showToast } = useToast()
  const history = useHistory()
  const { productId } = useParams() as { productId: string }

  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState<CategoryProps[]>([])

  const schema = yup.object().shape({
    name: yup.string().required(),
    price: yup.number().required(),
    priceSale: yup.number().required(),
    sku: yup.string().nullable(),
    categories: yup.array().of(yup.string()).min(1).required(),
    tags: yup.array().of(yup.string()).nullable()
  })

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<ProductDataProps>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      price: 0,
      priceSale: 0,
      categories: [],
      tags: [],
      manageStock: true,
      unitType: 'UN'
    }
  })

  const loadData = useCallback(async () => {
    const { data: categoriesData } = await apiv2.get<CategoryProps[]>(
      'category'
    )
    setCategories(categoriesData)

    if (productId) {
      const { data: productData } = await apiv2.get(`product/${productId}`)

      setValue('image', productData.image)
      setValue('name', productData.name)
      setValue('price', productData.price)
      setValue('priceSale', productData.priceSale)
      setValue('sku', productData.sku)
      setValue('manageStock', productData.manageStock)
      setValue('unitType', productData.unitType)

      setValue(
        'categories',
        productData.categories.map((category) => category.categoryId)
      )
      setValue(
        'tags',
        productData.tags.map((tag) => tag.tag.name)
      )
    }

    setIsLoading(false)
  }, [productId, setValue])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onSubmit = async (data: ProductDataProps) => {
    if (productId) {
      const { status } = await apiv2.put(`product/${productId}`, data)
      if (status === 200) {
        showToast({
          type: 'success',
          message: 'Produto atualizado com sucesso!'
        })
      }
    } else {
      const { status, data: responseData } = await apiv2.post('product', data)
      if (status === 200) {
        showToast({
          type: 'success',
          message: 'Produto cadastrado com sucesso!'
        })
        history.push(`/dashboard/produtos/${responseData.productId}`)
      }
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.Content>
        <ProductContentLeft
          control={control}
          register={register}
          setValue={setValue}
          watch={watch}
          errors={errors}
        />

        <ProductContentRight
          register={register}
          setValue={setValue}
          watch={watch}
          errors={errors}
          categories={categories}
        />
      </S.Content>

      {/* <TabComponent
        items={[
          {
            icon: <FiBox />,
            name: 'Estoque',
            content: (
              <ProductStock
                control={control}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
              />
            )
          }
        ]}
      /> */}

      <S.Footer>
        <Link to="/dashboard/produtos">
          <button type="button">Voltar</button>
        </Link>
        <button type="submit">Salvar</button>
      </S.Footer>
    </S.Container>
  )
}

export default Product
