import React, { useCallback } from 'react'
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ChartPieSlice,
  Browsers,
  ShoppingCart,
  Wrench,
  SignOut
} from 'phosphor-react'
import { FiBox } from 'react-icons/fi'

import { useWindowSize } from 'hooks/useWindowSize'
import { logout } from 'services/auth'

import SidebarMobile from './SidebarMobile'

import * as S from './styles'

interface Props {
  isSidebarOpen: boolean
  isSidebarExpanded: boolean
  setIsSidebarExpanded(isSidebarExpanded: boolean): void
  handleCloseSidebar(): void
}

const Sidebar: React.FC<Props> = ({
  isSidebarOpen,
  isSidebarExpanded,
  setIsSidebarExpanded,
  handleCloseSidebar
}) => {
  const location = useLocation()
  const history = useHistory()
  const [width] = useWindowSize()

  const handlerExpand = useCallback(
    (value) => () => {
      setIsSidebarExpanded(value)
    },
    [setIsSidebarExpanded]
  )

  const handleOnSelect = useCallback(
    (selected) => {
      if (location.pathname !== selected) {
        history.push(selected)
      }
    },
    [history, location.pathname]
  )

  return (
    <S.Container>
      {width > 768 ? (
        <div>
          <SideNav
            onSelect={handleOnSelect}
            onToggle={handlerExpand(true)}
            expanded={isSidebarExpanded}
          >
            <SideNav.Toggle />

            <div className="logo">
              <img
                src="https://big10.com.br/wp-content/uploads/2020/12/BANNER_8.png"
                alt="Fast Mercado"
                draggable={false}
              />

              <span>Minha loja</span>
            </div>

            <SideNav.Nav>
              <NavItem
                className="sidebar-icon"
                eventKey="/dashboard/"
                active={['/dashboard/', '/dashboard'].includes(
                  location.pathname
                )}
              >
                <NavIcon>
                  <div className="custom-sidebar-icon">
                    <ChartPieSlice size={24} />
                  </div>
                </NavIcon>
                <NavText>
                  <div className="custom-sidebar-text">Inicio</div>
                </NavText>
              </NavItem>

              <NavItem
                className="sidebar-icon"
                eventKey="/dashboard/paginas"
                active={['/dashboard/paginas', '/dashboard/paginas/'].includes(
                  location.pathname
                )}
              >
                <NavIcon>
                  <Browsers size={24} />
                </NavIcon>

                <NavText>
                  <div className="custom-sidebar-text">Páginas</div>
                </NavText>
              </NavItem>

              <NavItem
                className="sidebar-icon"
                eventKey="/dashboard/produtos"
                active={location.pathname.includes('/dashboard/produtos')}
              >
                <NavIcon>
                  <FiBox size={24} />
                </NavIcon>

                <NavText>
                  <div className="custom-sidebar-text">Produtos</div>
                </NavText>
              </NavItem>

              <NavItem
                className="sidebar-icon"
                eventKey="/dashboard/pedidos"
                active={['/dashboard/pedidos', '/dashboard/pedidos/'].includes(
                  location.pathname
                )}
              >
                <NavIcon>
                  <ShoppingCart size={24} />
                </NavIcon>

                <NavText>
                  <div className="custom-sidebar-text">Pedidos</div>
                </NavText>
              </NavItem>

              <NavItem
                className="sidebar-icon"
                eventKey="/dashboard/configuracoes"
                active={[
                  '/dashboard/configuracoes',
                  '/dashboard/configuracoes/'
                ].includes(location.pathname)}
              >
                <NavIcon>
                  <Wrench size={24} />
                </NavIcon>

                <NavText>
                  <div className="custom-sidebar-text">Configurações</div>
                </NavText>
              </NavItem>

              <NavItem
                className="sidebar-icon"
                eventKey="leave"
                onClick={logout}
              >
                <NavIcon>
                  <SignOut size={24} />
                </NavIcon>

                <NavText>
                  <div className="custom-sidebar-text">Sair</div>
                </NavText>
              </NavItem>
            </SideNav.Nav>
          </SideNav>
        </div>
      ) : (
        <SidebarMobile
          isSidebarOpen={isSidebarOpen}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </S.Container>
  )
}

export default Sidebar
