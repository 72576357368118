import styled from 'styled-components'
import InputCurrency from 'react-intl-currency-input'

export const Input = styled.input`
  padding: 1rem;
  width: 100%;
  font-size: 1.5rem;
  min-height: 3.5rem;
  box-shadow: 1px 1px 5px 0 #e9e9e9;
  outline: none;

  background: #fff;
  border: 1px solid #ccc;

  &:not([type='checkbox']):focus {
    border-color: #20a8d8;
    outline: none;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  /* CHECKBOX */
  &[type='checkbox'] {
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: ${(props) => props.width ?? '3rem'};
    height: ${(props) => props.width ?? '3rem'};
    min-height: ${(props) => props.width ?? '3rem'};
  }

  &[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &[type='checkbox']:checked:before {
    content: '✔';
    position: absolute;
    top: 0;
    left: 0;
    color: #17a2b8;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const InputMoney = styled(InputCurrency)`
  padding: 1rem;
  width: 100%;
  font-size: 1.5rem;
  display: inline-block;
  min-height: 3.5rem;
  max-width: 100%;
  box-shadow: 1px 1px 5px 0 #e9e9e9;
  outline: none;

  background-color: #fff;
  border: 1px solid #ccc;

  &:focus {
    border-color: #20a8d8;
    outline: none;
  }

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`
