import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ToastProvider } from './ToastContext'
import { MarketProvider } from './MarketContext'
import { OrderProvider } from './OrderContext'

const AppProvider: React.FC = ({ children }) => (
  <BrowserRouter>
    <ToastProvider>
      <MarketProvider>
        <OrderProvider>{children}</OrderProvider>
      </MarketProvider>
    </ToastProvider>
  </BrowserRouter>
)

export default AppProvider
