import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import InputComponent from 'components/Input'

import { useToast } from 'contexts/ToastContext'
import { useMarket } from 'contexts/MarketContext'
import { api } from 'services/api'

import * as S from './styles'

interface FormData {
  address: string
  city: string
  state: string
  postcode: string
  country: string
  uf: string
}

const Location: React.FC = () => {
  const { showToast } = useToast()
  const { market, handleMarketUpdate } = useMarket()
  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object().shape({
    address: yup.string().required('Endereço obrigatório'),
    city: yup.string().required('Cidade obrigatório'),
    state: yup.string().required('Estado obrigatório'),
    postcode: yup.string().required('CEP obrigatório'),
    country: yup.string().required('País obrigatório'),
    uf: yup.string().required('UF obrigatório')
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (market) {
      setValue('address', market.address.address, { shouldValidate: true })
      setValue('city', market.address.city, { shouldValidate: true })
      setValue('state', market.address.state, { shouldValidate: true })
      setValue('postcode', market.address.postcode, { shouldValidate: true })
      setValue('country', market.address.country, { shouldValidate: true })
      setValue('uf', market.address.uf, { shouldValidate: true })
    }
  }, [setValue, market])

  const onSubmit = async (data: FormData) => {
    setIsLoading(true)

    try {
      const { data: marketData } = await api.put('markets', {
        address: data
      })

      handleMarketUpdate(marketData)

      showToast({
        type: 'success',
        message: 'Mercado atualizado com sucesso'
      })
    } catch (err) {
      const message =
        err?.response?.data?.error || 'Por Favor entrar em contato com suporte.'

      showToast({
        type: 'error',
        message
      })
    } finally {
      setIsLoading(false)
    }
    // } else {
    //   const message = 'A validação do Recaptcha falhou.'
    //   showToast({ message, type: 'error' })
    // }
  }

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.Title>Endereço da loja</S.Title>

      <S.Col>
        <InputComponent
          id="address"
          name="address"
          label="Rua"
          className={errors.address && 'is-invalid'}
          {...register('address')}
        />
      </S.Col>
      <S.Col>
        <InputComponent
          id="city"
          name="city"
          label="Cidade"
          className={errors.city && 'is-invalid'}
          {...register('city')}
        />
      </S.Col>
      <S.Col>
        <InputComponent
          id="postcode"
          name="postcode"
          label="CEP"
          className={errors.postcode && 'is-invalid'}
          {...register('postcode')}
        />
      </S.Col>
      <S.Col>
        <InputComponent
          id="country"
          name="country"
          label="País"
          className={errors.country && 'is-invalid'}
          {...register('country')}
        />
      </S.Col>
      <S.Col>
        <InputComponent
          id="uf"
          name="uf"
          label="Estado/País"
          className={errors.uf && 'is-invalid'}
          {...register('uf')}
        />
      </S.Col>
    </S.Container>
  )
}

export default Location
