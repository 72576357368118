import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 20rem;
  height: 20rem;

  label {
    cursor: pointer;

    img,
    div {
      width: 20rem;
      height: 20rem;
      object-fit: contain;
      font-size: 1.5rem;
      box-shadow: 1px 1px 5px 0 #e9e9e9;
      outline: none;

      background: #fff;
      border: 1px solid #ccc;
    }

    input {
      display: none;
    }
  }

  > button {
    position: absolute;
    top: 1rem;
    left: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.2rem;
    color: #ffffff;
    background: #17a2b8;
    border-radius: 0.3rem;
  }
`
