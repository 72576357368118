import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Wallet, Money, ArchiveBox, ShoppingCart } from 'phosphor-react'

import { useMarket } from 'contexts/MarketContext'
import { api } from 'services/api'
import { formatPrice } from 'utils/utils'

import * as S from './styles'

const Cards: React.FC = () => {
  const { market } = useMarket()

  const [orderQuantity, setOrderQuantity] = useState(0)
  const [orderSubtotal, setOrderSubtotal] = useState(0)
  const [orderTotal, setOrderTotal] = useState(0)

  const handleLoadTotal = useCallback(async () => {
    if (market) {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD')

      const { data } = await api.get(`orders/${market.id}/total/payment`, {
        headers: {
          startDate: startOfMonth,
          endDate: endOfMonth
        }
      })

      setOrderQuantity(data.orderQuantity)
      setOrderSubtotal(data.orderSubtotal)
      setOrderTotal(data.orderTotal)
    }
  }, [market])

  useEffect(() => {
    handleLoadTotal()
  }, [handleLoadTotal])

  return (
    <S.Container>
      <S.Card>
        <S.CardIcon color="#f8cb00">
          <ArchiveBox size={32} />
        </S.CardIcon>
        <S.CardContent>
          <S.CardValue>{orderQuantity}</S.CardValue>
          <S.CardSummary>vendas neste mês</S.CardSummary>
        </S.CardContent>
      </S.Card>

      <S.Card>
        <S.CardIcon color="#f86c6b">
          <Wallet size={32} />
        </S.CardIcon>
        <S.CardContent>
          <S.CardValue>{formatPrice(orderTotal)}</S.CardValue>
          <S.CardSummary>vendas brutas neste mês</S.CardSummary>
        </S.CardContent>
      </S.Card>

      <S.Card>
        <S.CardIcon color="#17a2b8">
          <ShoppingCart size={32} />
        </S.CardIcon>
        <S.CardContent>
          <S.CardValue>{formatPrice(orderSubtotal)}</S.CardValue>
          <S.CardSummary>recebido neste mês</S.CardSummary>
        </S.CardContent>
      </S.Card>

      <S.Card>
        <S.CardIcon color="#20a8d8">
          <Money size={32} />
        </S.CardIcon>
        <S.CardContent>
          <S.CardValue>{formatPrice(orderTotal - orderSubtotal)}</S.CardValue>
          <S.CardSummary>diferença vendas brutas</S.CardSummary>
        </S.CardContent>
      </S.Card>
    </S.Container>
  )
}

export default Cards
