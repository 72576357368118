import React from 'react'

import InputComponent from 'components/Input'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'

import SelectComponent from 'components/SelectComponent'

import type { ProductDataProps } from '..'

import * as S from './styles'

interface Props {
  control: Control<ProductDataProps>
  register: UseFormRegister<ProductDataProps>
  setValue: UseFormSetValue<ProductDataProps>
  watch: UseFormWatch<ProductDataProps>
  errors: FieldErrors<ProductDataProps>
}

const unitTypes = [
  { value: 'UN', label: 'UN - Unidade' },
  { value: 'KG', label: 'KG - Quilograma' }
]

const ProductContentLeft: React.FC<Props> = ({
  control,
  setValue,
  watch,
  errors
}) => {
  const price = watch('price')
  const priceSale = watch('priceSale')
  const sku = watch('sku')

  const handleChangePrice = async (value: number) => {
    setValue('price', value)
  }

  const handleChangePriceSale = async (value: number) => {
    setValue('priceSale', value)
  }

  const handleChangeSKU = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setValue('sku', value)
  }

  return (
    <S.Container>
      <S.Col>
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange } }) => (
            <InputComponent
              label="Nome"
              className={errors.name && 'is-invalid'}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </S.Col>

      <S.Col>
        <InputComponent
          money
          label="Preço"
          className={errors.price && 'is-invalid'}
          value={price}
          onChange={(e, value: number) => handleChangePrice(value)}
        />
        <InputComponent
          money
          label="Preço de venda"
          className={errors.priceSale && 'is-invalid'}
          value={priceSale}
          onChange={(e, value: number) => handleChangePriceSale(value)}
        />
      </S.Col>

      <S.Col>
        <Controller
          control={control}
          name="unitType"
          render={({ field: { value, onChange } }) => (
            <SelectComponent
              label="Unidade de medida"
              options={unitTypes}
              className={errors.unitType && 'is-invalid'}
              value={unitTypes.find((c) => c.value === value)}
              onChange={(val: { value: string }) => onChange(val.value)}
            />
          )}
        />
      </S.Col>

      <S.Col>
        <InputComponent
          label="SKU"
          className={errors.sku && 'is-invalid'}
          value={sku}
          onChange={handleChangeSKU}
        />
      </S.Col>

      <S.Col>
        <Controller
          control={control}
          name="manageStock"
          render={({ field: { value, onChange } }) => (
            <InputComponent
              type="checkbox"
              label="Gerenciar estoque?"
              className={errors.manageStock && 'is-invalid'}
              checked={value}
              onChange={(val) => onChange(val)}
            />
          )}
        />
      </S.Col>
    </S.Container>
  )
}

export default ProductContentLeft
