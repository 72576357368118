import styled from 'styled-components'

export const Container = styled.label`
  min-width: 20rem;
  font-size: 1.6rem;
  font-style: italic;
  font-weight: 600;

  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
  }
`
