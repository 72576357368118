import React, { Fragment } from 'react'
import { Props as SelectProps } from 'react-select'

import LabelComponent from 'components/LabelComponent'

import * as S from './styles'

interface Props extends SelectProps {
  label?: string
  isMultiSelect?: boolean
}

const SelectComponent: React.FC<Props> = ({
  label,
  isMultiSelect = false,
  ...rest
}) => (
  <Fragment>
    {label && <LabelComponent htmlFor={rest.id}>{label}</LabelComponent>}

    <S.Container
      placeholder=""
      isMulti={isMultiSelect}
      {...rest}
      classNamePrefix="react-select"
      theme={undefined}
      noOptionsMessage={() => 'Nenhuma opção encontrada!'}
    />
  </Fragment>
)

export default SelectComponent
