import React, { useState } from 'react'

import InputComponent from 'components/Input'

import * as S from './styles'

const Sambanet: React.FC = () => {
  const [marketAddress, setMarketAddress] = useState('')

  return (
    <S.Container>
      <S.Title>Configuração das credenciais</S.Title>

      <S.Col>
        <InputComponent
          id="marketAddress"
          label="Token da empresa"
          value={marketAddress}
          onChange={(e) => setMarketAddress(e.target.value)}
        />
      </S.Col>

      <S.Col>
        <InputComponent
          id="marketAddress"
          label="Token da loja"
          value={marketAddress}
          onChange={(e) => setMarketAddress(e.target.value)}
        />
      </S.Col>

      <S.Col>
        <InputComponent
          id="user"
          label="Usuário"
          value={marketAddress}
          onChange={(e) => setMarketAddress(e.target.value)}
        />
      </S.Col>

      <S.Col>
        <InputComponent
          id="userPassword"
          type="password"
          label="Senha do usuário"
          value={marketAddress}
          onChange={(e) => setMarketAddress(e.target.value)}
        />
      </S.Col>

      <S.Col>
        <InputComponent
          id="salesman"
          type="number"
          label="Código do vendedor"
          value={marketAddress}
          onChange={(e) => setMarketAddress(e.target.value)}
        />
      </S.Col>
    </S.Container>
  )
}

export default Sambanet
