import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import 'rc-drawer/assets/index.css'
import {
  ChartPieSlice,
  Browsers,
  ShoppingCart,
  Wrench,
  SignOut
} from 'phosphor-react'
import { FiBox } from 'react-icons/fi'

import * as S from './styles'
import { logout } from 'services/auth'

interface Props {
  isSidebarOpen: boolean
  handleCloseSidebar(): void
}

const SidebarMobile: React.FC<Props> = ({
  isSidebarOpen = false,
  handleCloseSidebar
}) => {
  const history = useHistory()
  const location = useLocation()

  const handleOnSelect = useCallback(
    (selected) => {
      const to = `/dashboard${selected}`

      if (location.pathname !== to) {
        history.push(to)
      }

      handleCloseSidebar()
    },
    [handleCloseSidebar, history, location.pathname]
  )

  const handleLogout = () => {
    history.push('/auth/login')
    logout()
  }

  return (
    <S.Drawer
      level={null}
      open={isSidebarOpen}
      handler={false}
      onClose={handleCloseSidebar}
      width="290px"
    >
      <S.Container>
        <S.Info>
          <img
            src="https://big10.com.br/wp-content/uploads/2020/12/BANNER_8.png"
            alt="Fast Mercado"
            draggable={false}
          />
        </S.Info>
        <S.List>
          <li>
            <S.Item
              title="Inicio"
              active={['/dashboard', '/dashboard/'].includes(location.pathname)}
              onClick={() => handleOnSelect('/')}
            >
              <ChartPieSlice size={24} />
              <span>Início</span>
            </S.Item>
          </li>

          <li>
            <S.Item
              title="Páginas"
              active={['/dashboard/paginas', '/dashboard/paginas/'].includes(
                location.pathname
              )}
              onClick={() => handleOnSelect('/paginas/')}
            >
              <Browsers size={24} />
              <span>Páginas</span>
            </S.Item>
          </li>

          <li>
            <S.Item
              title="Produtos"
              active={location.pathname.includes('/dashboard/produtos')}
              onClick={() => handleOnSelect('/produtos/')}
            >
              <FiBox size={24} />
              <span>Produtos</span>
            </S.Item>
          </li>

          <li>
            <S.Item
              title="Pedidos"
              active={['/dashboard/pedidos', '/dashboard/pedidos/'].includes(
                location.pathname
              )}
              onClick={() => handleOnSelect('/pedidos/')}
            >
              <ShoppingCart size={24} />
              <span>Pedidos</span>
            </S.Item>
          </li>

          <li>
            <S.Item
              title="Configurações"
              active={[
                '/dashboard/configuracoes',
                '/dashboard/configuracoes/'
              ].includes(location.pathname)}
              onClick={() => handleOnSelect('/configuracoes/')}
            >
              <Wrench size={24} />
              <span>Configurações</span>
            </S.Item>
          </li>

          <li>
            <S.Item title="Sair" onClick={handleLogout}>
              <SignOut size={24} />
              <span>Sair</span>
            </S.Item>
          </li>
        </S.List>
      </S.Container>
    </S.Drawer>
  )
}

export default SidebarMobile
