import React from 'react'

import Cards from './Cards'

import * as S from './styles'

const Dashboard: React.FC = () => {
  return (
    <S.Container>
      <Cards />
    </S.Container>
  )
}

export default Dashboard
