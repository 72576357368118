import styled, { keyframes } from 'styled-components'
import media from 'styled-media-query'

import loginImg from '../../../assets/img/login.jpg'

const grow = keyframes`
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
`

export const Body = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: row;

  .divLoading {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 2.5rem;
  }

  .spanLoading {
    width: 0.2em;
    height: 0.5em;
    background-color: white;
    margin: 5px 0px;
  }

  .spanLoading:nth-of-type(1) {
    animation: ${grow} 1s -0.45s ease-in-out infinite;
  }

  .spanLoading:nth-of-type(2) {
    animation: ${grow} 1s -0.3s ease-in-out infinite;
  }

  .spanLoading:nth-of-type(3) {
    animation: ${grow} 1s -0.15s ease-in-out infinite;
  }

  .spanLoading:nth-of-type(4) {
    animation: ${grow} 1s ease-in-out infinite;
  }
`

export const Left = styled.div`
  background: #020216e8;
  background-image: url(${loginImg});
  background-size: cover;
  background-position: center;
  height: 100%;
  /* width: 45%; */
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lessThan('medium')`
    width: 100%;

    padding: 2rem;
  `}
`

export const Right = styled.div`
  background: #020216e8;
  background-image: url(${loginImg});
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  height: 100%;
  width: 55%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const Form = styled.form`
  display: flex;
  gap: 2rem;
  flex-direction: column;

  width: 100%;
  max-width: 400px;
  background: #333;
  padding: 3rem;
  border-radius: 8px;
`

export const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const Label = styled.label`
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;

  margin-bottom: 1rem;
`

export const Input = styled.input`
  width: 100%;
  min-width: 0px;
  outline: 0;
  position: relative;
  appearance: none;
  transition: all 0.2s;
  font-size: 1.5rem;
  height: 5rem;
  border-radius: 0.375rem;
  border: 2px solid transparent;
  background: #181b23;
  color: #ffffff;

  padding: 1px 16px;

  &:hover,
  &:focus {
    border-color: #fdc10d;
  }

  &.is-invalid {
    border-color: #e53e3e;
  }
`

export const Error = styled.span`
  display: flex;
  color: #e53e3e;
  margin-top: 1rem;
  font-size: 1.5rem;
`

export const Button = styled.button`
  background: #ff1d15;
  color: white;
  padding: 1.5rem 2rem;
  border: none;
  font-weight: bold;
  font-size: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`
