import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 35rem;

  @media (max-width: 1280px) {
    max-width: 100%;
  }
`

export const Col = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;

  margin-bottom: 1rem;
`

export const Categories = styled.div`
  border: 1px solid #ccc;
  box-shadow: 1px 1px 5px 0 #e9e9e9;

  &.is-invalid {
    border-color: ${(props) => props.theme.colors.red};
  }

  > header {
    padding: 1rem;
    border-bottom: 1px solid #ccc;
  }

  > div {
    overflow-y: scroll;
    background-color: #fdfdfd;
    padding: 1rem;
    min-height: 4rem;
    max-height: 20rem;
  }
`

export const Category = styled.div`
  display: flex;
  margin-bottom: 1rem;

  input {
    margin-right: 1rem;
  }

  label {
  }
`
